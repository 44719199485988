import React from "react";
import "swiper/swiper-bundle.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

import { NavBar, Footer } from "./components";
import {
  PRESIDENTS,
  VICEPRESIDENTS,
  SECRETARYGENERALS,
  HONTREASURERS,
  HONCOUNCILMEMBERS,
  HONSOCIALSECRETARYS,
  LEGALADVISERS,
  MEMBERSHIPSECRETARYS,
  GENERALMANAGERS,
  SPORTSSECRETARYS,
  AEROBICSTAKEWONDOS,
  CHAIRMANSCRABBLES,
  CHAIRMANSQUASHS,
  CHAIRMANTENNISS,
  CHAIRMANBADMINTONS,
  CHAIREMANDARTS,
  CHAIRMANTABLES,
  OTHERGAMES,
  CHAIRMANSWIMMINGS,
  CHAIRMANSNOOKERS,
} from "./pages/sections/TeamDetails";
import {
  Home,
  About,
  Login,
  News,
  Contact,
  SectionTennis,
  SectionSnooker,
  SectionDart,
  SectionSwimming,
  SectionTaekwondo,
  SectionScrabble,
  SectionBadminton,
  SectionSquash,
  SectionOtherGames,
  PasswordReset,
  Events,
  Details,
  Managements,
  PasswordChange,
  TTennis,
  protocolCovids,
  NewHandings,
  EventsHandings,
  
} from "./pages";
import HallBooking from "./pages/hallBooking/HallBooking";
import HallBookingRes from "./pages/hallBooking/hallBookingRes";
import EventsD from "./pages/clubEvents/details";
import BlogsD from "./components/card/blogdetails";
import routes from "./routes/routes";
import VerifyOTP from "./pages/auth/verifyOTP";
import { MemberProvider } from "./context/memberContext";
const queryClient = new QueryClient();

export default function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router>
          <MemberProvider>
            {/* <!-- header and Navigation --> */}
            <NavBar />
            {/* {/* <!-- header END -->	<!-- header END --> */}
            <Switch>
              <Route exact path={routes.public.home} component={Home} />
              <Route path={routes.public.about} exact component={About} />
              <Route path={routes.public.contact} exact component={Contact} />
              <Route path={routes.public.clubNews} exact component={News} />
              <Route path={routes.public.newDetail_1} exact component={NewHandings} />
              <Route path={routes.public.clubEvents} exact component={Events} />
              <Route path="/events/:id" exact component={EventsD} />
              <Route path="/Blogs/:id" exact component={BlogsD} />
              <Route path={routes.public.EventsHanding} exact component={EventsHandings} />
              <Route path={routes.public.protocolCovid} exact component={protocolCovids} />
              <Route
                path={routes.public.eventDetails}
                exact
                component={Details}
              />
              <Route path={routes.public.hallBooking} exact component={HallBooking} />
              <Route path={routes.public.hallBookingRes} exact component={HallBookingRes} />
              <Route
                path={routes.public.management}
                exact
                component={Managements}
              />

              <Route path="/PRESIDENT" exact component={PRESIDENTS} />
              <Route path="/VICEPRESIDENT" exact component={VICEPRESIDENTS} />
              <Route
                path="/SECRETARYGENERAL"
                exact
                component={SECRETARYGENERALS}
              />
              <Route path="/HONTREASURER" exact component={HONTREASURERS} />
              <Route
                path="/HONCOUNCILMEMBER"
                exact
                component={HONCOUNCILMEMBERS}
              />
              <Route
                path="/HONSOCIALSECRETARY"
                exact
                component={HONSOCIALSECRETARYS}
              />
              <Route path="/LEGALADVISER" exact component={LEGALADVISERS} />
              <Route
                path="/MEMBERSHIPSECRETARY"
                exact
                component={MEMBERSHIPSECRETARYS}
              />
              <Route path="/GENERALMANAGER" exact component={GENERALMANAGERS} />
              <Route
                path="/SPORTSSECRETARY"
                exact
                component={SPORTSSECRETARYS}
              />
              <Route
                path="/AEROBICSTAKEWONDO"
                exact
                component={AEROBICSTAKEWONDOS}
              />
              <Route
                path="/CHAIRMANSCRABBLE"
                exact
                component={CHAIRMANSCRABBLES}
              />
              <Route path="/CHAIRMANSQUASH" exact component={CHAIRMANSQUASHS} />
              <Route path="/CHAIRMANTENNIS" exact component={CHAIRMANTENNISS} />
              <Route
                path="/CHAIRMANBADMINTON"
                exact
                component={CHAIRMANBADMINTONS}
              />
              <Route path="/CHAIREMANDARTT" exact component={CHAIREMANDARTS} />
              <Route path="/CHAIRMANTABLE" exact component={CHAIRMANTABLES} />
              <Route path="/OTHERGAME" exact component={OTHERGAMES} />
              <Route
                path="/CHAIRMANSWIMMING"
                exact
                component={CHAIRMANSWIMMINGS}
              />
              <Route
                path="/CHAIRMANSNOOKER"
                exact
                component={CHAIRMANSNOOKERS}
              />

              <Route path={routes.public.login} exact component={Login} />
              <Route
                path={routes.public.resetPassword}
                exact
                component={PasswordReset}
              />
              <Route
                path={routes.public.changePassword}
                exact
                component={PasswordChange}
              />
              <Route
                path={routes.public.verifyOTP}
                exact
                component={VerifyOTP}
              />
              <Route
                path={routes.public.tableTennis}
                exact
                component={TTennis}
              />

              <Route
                path={routes.public.tennisSection}
                exact
                component={SectionTennis}
              />
              <Route
                path={routes.public.snookerSection}
                exact
                component={SectionSnooker}
              />
              <Route
                path={routes.public.dartSection}
                exact
                component={SectionDart}
              />
              <Route
                path={routes.public.swimmingSection}
                exact
                component={SectionSwimming}
              />
              <Route
                path={routes.public.scrabbleSection}
                exact
                component={SectionScrabble}
              />
              <Route
                path={routes.public.badmintonSection}
                exact
                component={SectionBadminton}
              />
              <Route
                path={routes.public.squashSection}
                exact
                component={SectionSquash}
              />
              <Route
                path={routes.public.otherGamesSection}
                exact
                component={SectionOtherGames}
              />
              <Route
                path={routes.public.taekwondoSection}
                exact
                component={SectionTaekwondo}
              />
            </Switch>
            {/* <!-- Footer --> */}
            <footer className="site-footer footer">
              <Footer />
            </footer>
            {/* <!-- Footer END--> */}
          </MemberProvider>
        </Router>
      </QueryClientProvider>
    </>
  );
}
