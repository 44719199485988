import React from "react";
import { PageHeader, TeamMember } from "../../components";
import TakwandoGallery from "./directory/takwando";
import style from "./style.module.css";
export default function Taekwondo() {
  return (
    <div className="page-content">
      <PageHeader title="Taekwondo " page="taekwondo" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- contact --> */}
        <div className="content">
          <div class="section-full bg-gray content-inner ">
            <div class="container">
              <div class="row">
                <div class="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="/assets/images/gallery/ta3.jpg"
                    alt=""
                    class="m-b30"
                  />
                </div>
                <div class="col-md-7 col-sm-12">
                  <h3 class="h2">WELCOME TO AEROBICS / TAEKWONDO</h3>
                  <div class="dlab-separator bg-primary"></div>
                  <h4 class="font-weight-3 00">
                    Taekwondo Aerobics Section came into existence in the mid
                    seventies; first as a Taekwondo Section where members and
                    their children who were interested in this Asian Sports made
                    popular by the influx of Chinese Films could learn and
                    practice this type of game.
                  </h4>
                  <p>
                    Like all new things, it started with a pioneering team led
                    by now Senator Uche Chukwumerije. He was the first Chairman
                    of this budding Section. No wonder his son became the first
                    and only Nigerian Olympic medalist in this sport to date.
                    The main hall was their practicing gym. But with
                    infrastructural development in the Club, the Section got a
                    space to house the game and its practitioners. However, in
                    the nineties, as health fitness consciousness became
                    popular, an aerobics group also emerged. It was then decided
                    that for synergy, aerobics group should merge with the
                    Taekwondo Section.
                  </p>
                  <p>
                    Of course this merger led to rapid development of the
                    Section to a formidable height in the Club. It is one
                    Section that has enhanced the family values of the Club.
                    Here the entire family members have the chance to recreate
                    in their kind of sporting activities. The father can enjoy
                    both Taekwondo and Aerobics while the mother also can
                    work-out in the gym and equally keep fit in the aerobics
                    classes. The children learn the art of self defense in the
                    Taekwondo classes at weekends. In the last ten years, the
                    children have won laurels in various competitions; the best
                    of which was that of the Olympic bronze medal won by Mr
                    Chukwumereije junior.
                  </p>

                  <p>
                    Committee Members:
                    <ul className={`${style.marginVertical}`}>
                      <li>Mr. Okey Ugwueze Vice Chairman</li>
                      <li>Mrs Bosede Shonowo Financial Secretary</li>
                      <li>Mrs Shola Akin-Adarijo Entertainmant Officer</li>
                      <li>Mr. Fidelis Chinedu Secreatary</li>
                      <li>Arc Tokunbo Ashiru Captain</li>
                      <li>Mrs Ayo Odiah Committee Member</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
        <TakwandoGallery />
        {/* <!-- Team member --> */}
        <TeamMember 
          name="Adetayo Adegboye"
          title="Chairman Aerobics/Takewondo."
          img="./assets/images/members/LCC_AEROBICS_TAKEWONDO.JPG" />
        {/* <!-- Team member --> */}
      </div>
    </div>
  );
}
