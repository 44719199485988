const items = [
  {
    id: 145,
    alt: "gallery image",
    category: "all",
    img: "./assets/images/gallery/d4.jpg",
  },
  {
    id: 135,
    alt: "gallery image",
    category: "all",
    img: "./assets/images/gallery/sn1.jpg",
  },
  {
    id: 113,
    alt: "gallery image",
    category: "all",
    img: "./assets/images/gallery/t4.jpg",
  },
  {
    id: 136,
    alt: "gallery image",
    category: "all",
    img: "./assets/images/gallery/ta4.jpg",
  },
  {
    id: 123,
    alt: "gallery image",
    category: "all",
    img: "./assets/images/gallery/tt5.jpg",
  },
  {
    id: 137,
    alt: "gallery image",
    category: "all",
    img: "./assets/images/gallery/sq3.jpg",
  },
  {
    id: 1,
    alt: "gallery image",
    category: "Snooker",
    img: "./assets/images/gallery/sn1.jpg",
  },
  {
    id: 2,
    alt: "gallery image",
    category: "Snooker",
    img: "./assets/images/gallery/sn2.jpg",
  },
  {
    id: 3,
    alt: "gallery image",
    category: "Snooker",
    img: "./assets/images/gallery/sn3.jpg",
  },
  {
    id: 4,
    alt: "gallery image",
    category: "Snooker",
    img: "./assets/images/gallery/sn4.jpg",
  },
  {
    id: 5,
    alt: "gallery image",
    category: "Snooker",
    img: "./assets/images/gallery/sn5.jpeg",
  },
  {
    id: 6,
    alt: "gallery image",
    category: "Snooker",
    img: "./assets/images/gallery/sn6.jpeg",
  },
  {
    id: 7,
    alt: "gallery image",
    category: "Snooker",
    img: "./assets/images/gallery/sn7.jpeg",
  },
  {
    id: 8,
    alt: "gallery image",
    category: "Snooker",
    img: "./assets/images/gallery/sn8.jpeg",
  },
  {
    id: 9,
    alt: "gallery image",
    category: "Swimming",
    img: "./assets/images/gallery/sw1.jpg",
  },
  {
    id: 10,
    alt: "gallery image",
    category: "Swimming",
    img: "./assets/images/gallery/sw3.jpg",
  },
  {
    id: 11,
    alt: "gallery image",
    category: "Swimming",
    img: "./assets/images/gallery/sw4.jpg",
  },
  {
    id: 12,
    alt: "gallery image",
    category: "Swimming",
    img: "./assets/images/gallery/sw2.jpg",
  },
  {
    id: 13,
    alt: "gallery image",
    category: "Swimming",
    img: "./assets/images/gallery/sw5.jpg",
  },
  {
    id: 14,
    alt: "gallery image",
    category: "Tennis",
    img: "./assets/images/gallery/t1.jpg",
  },
  {
    id: 15,
    alt: "gallery image",
    category: "Tennis",
    img: "./assets/images/gallery/t2.jpg",
  },
  {
    id: 16,
    alt: "gallery image",
    category: "Tennis",
    img: "./assets/images/gallery/t3.jpg",
  },
  {
    id: 17,
    alt: "gallery image",
    category: "Tennis",
    img: "./assets/images/gallery/t4.jpg",
  },
  {
    id: 18,
    alt: "gallery image",
    category: "Darts",
    img: "./assets/images/gallery/d4.jpg",
  },
  {
    id: 19,
    alt: "gallery image",
    category: "Darts",
    img: "./assets/images/gallery/d1.jpg",
  },
  {
    id: 20,
    alt: "gallery image",
    category: "Darts",
    img: "./assets/images/gallery/d2.jpg",
  },
  {
    id: 21,
    alt: "gallery image",
    category: "Badmintom",
    img: "./assets/images/gallery/b1.jpg",
  },
  {
    id: 22,
    alt: "gallery image",
    category: "Badmintom",
    img: "./assets/images/gallery/b2.jpg",
  },
  {
    id: 23,
    alt: "gallery image",
    category: "Badmintom",
    img: "./assets/images/gallery/b3.jpg",
  },
  {
    id: 24,
    alt: "gallery image",
    category: "Badmintom",
    img: "./assets/images/gallery/b4.jpg",
  },
  {
    id: 25,
    alt: "gallery image",
    category: "Table Tennis",
    img: "./assets/images/gallery/tt1.jpg",
  },
  {
    id: 26,
    alt: "gallery image",
    category: "Table Tennis",
    img: "./assets/images/gallery/tt4.jpg",
  },
  {
    id: 27,
    alt: "gallery image",
    category: "Table Tennis",
    img: "./assets/images/gallery/tt5.jpg",
  },
  {
    id: 28,
    alt: "gallery image",
    category: "Table Tennis",
    img: "./assets/images/gallery/tt2.jpg",
  },
  {
    id: 29,
    alt: "gallery image",
    category: "Table Tennis",
    img: "./assets/images/gallery/tt3.png",
  },
  {
    id: 30,
    alt: "gallery image",
    category: "Squash",
    img: "./assets/images/gallery/sq1.jpg",
  },
  {
    id: 31,
    alt: "gallery image",
    category: "Squash",
    img: "./assets/images/gallery/sq2.jpg",
  },
  {
    id: 32,
    alt: "gallery image",
    category: "Squash",
    img: "./assets/images/gallery/sq3.jpg",
  },
  {
    id: 33,
    alt: "gallery image",
    category: "Scrabble",
    img: "./assets/images/gallery/sc1.jpg",
  },
  {
    id: 34,
    alt: "gallery image",
    category: "Scrabble",
    img: "./assets/images/gallery/sc2.jpg",
  },
  {
    id: 35,
    alt: "gallery image",
    category: "Scrabble",
    img: "./assets/images/gallery/sc3.jpeg",
  },
  {
    id: 36,
    alt: "gallery image",
    category: "Scrabble",
    img: "./assets/images/gallery/sc4.jpeg",
  },
  {
    id: 37,
    alt: "gallery image",
    category: "Scrabble",
    img: "./assets/images/gallery/sc5.jpeg",
  },
  {
    id: 38,
    alt: "gallery image",
    category: "Scrabble",
    img: "./assets/images/gallery/sc6.jpeg",
  },
  {
    id: 39,
    alt: "gallery image",
    category: "Scrabble",
    img: "./assets/images/gallery/sc7.jpeg",
  },
  {
    id: 40,
    alt: "gallery image",
    category: "Taekwondo",
    img: "./assets/images/gallery/ta1.jpg",
  },
  {
    id: 41,
    alt: "gallery image",
    category: "Taekwondo",
    img: "./assets/images/gallery/ta2.jpg",
  },
  {
    id: 42,
    alt: "gallery image",
    category: "Taekwondo",
    img: "./assets/images/gallery/ta3.jpg",
  },
  {
    id: 43,
    alt: "gallery image",
    category: "Taekwondo",
    img: "./assets/images/gallery/ta4.jpg",
  },
];
export default items;
