const CARD_DATA = [
  {
    Items: [
      {
        id: 1,
        title: "President",
        name: "MR SEYI ADEWUNMI",
        imageUrl: "./assets/images/members/LCC_PRESIDENT.JPEG",
        desc: "",
        link: "/PRESIDENT",
      },
      {
        id: 2,
        title: "Vice President",
        name: "ENGR OLATUNJI AMOSU",
        imageUrl: "./assets/images/members/LCC_VICE_PRESIDENT.jpeg",
        link: "/VICEPRESIDENT",
        desc:
          `Engr Olatunji Amosu is the MD/CEO Boltons Nigeria Limited, an international outfit. He holds a Bachelor’s Degree in Public Administration from the Lagos State University, and Diploma in Biomedical Communications as well as Diploma in Computer Elect from University of Lagos, he also has a Cert in Technical Training from Terumo Europe NV, Belgium.
          Engr Tunji Amosu is married with children and enjoys sports and social activities. He is a member of several Social Clubs and Societies and have served in various capacity in the Club, particularly Darts section where he was Entertainment officer, Secretary, Vice Chairman and Chairman.`,
      },
      {
        id: 3,
        title: "Secretary General",
        name: "DR. TUNDE BAJELA",
        imageUrl: "./assets/images/members/LCC_SECRETARY_GENERAL.JPEG",
        desc:
          `Dr Tunde Bajela is the Managing Consultant/Chief Executive of Superbrains Consulting Limited, a financial advisory company and the Managing Director/Chief Executive of Hygges Signature Limited, a real estate company. He is also the managing partner of the firm of Chartered Accountants, Tunde Bajela & Co. His professional certifications include the fellowships of both the Chartered Institute of Bankers of Nigeria (FCIB) and the Institute of Chartered Accountants of Nigeria (FCA). He is also a professional member of the Institute of Marketing of Nigeria (NIMN) as well as a Chartered Arbitrator. With over 3 decades of professional experience in banking, finance, and accounting, he earned a Bachelor of Laws (LLB) degree from the university of London, a Masters degree in Applied Business Research and doctorate degree in business administration, both from the Swiss Business School, Zurich Switzerland. 
          Armed with a Masters of Business Administration (MBA) degree acquired after a Bachelor of Science degree from the University of Ife (Now Obafemi Awolowo University) in 1987, Tunde had a fulfilling and highly distinguished career spanning over 21 years in the banking industry, where he held top management positions with leadership responsibilities in the areas of Retail, Commercial and Corporate Banking industry in January 2012, he established Superbrains Consulting Limited a financial advisory firm that is involved in delivering high quality consultancy services to private and public sector, organizations across the Country in Credit Risk Management, Accounting, Taxation, Corporate Finance and Strategic Planning and Arbitration. In 20018, he founded the professional service firm, Tunde Bajela and Co. (Chartered Accountants) to focus specifically on accounting, taxation and auditing. Later in 2020, he partnered with other investors to set up Hygges Signature Limited, a real estate company.
          Tunde has participated in more than 200 professional seminars, conferences and training programmes at home and abroad and he is an alumnus of 3 prestigious business school, the Lagos Business School (Senior Management Programme) Havard Business School (programme for leadership Development) INSEAD Fontainebleau, France (Risk Management in Banking). His involvement with the public sector in the area of policy articulation and development in his career includes membership of the FGN/NNPC delegation on a tour of China in 2007, to inspect industrial parks, petrochemical plants and refineries with a view to providing guidance for the development of an industrial policy for Nigeria. Tunde is happily married and blessed with children and his hobbies includes, music, sports, travelling and current affairs. He is a member of Lagos Country Club, the IBB Golf Club, and the Ikeja Golf Club.
          `,
        link: "/SECRETARYGENERAL",
      },
      {
        id: 4,
        title: "Hon. Treasurer",
        name: "OTUNBA ABIMBOLA OLANIYI",
        imageUrl: "./assets/images/members/LCC_TREASURER.JPEG",
        desc:
          `Brief Resume of Otunba Abimbola Omotunde Olaniyi-FCA, ACS, AMNIM, FRCN
          A fellow of the Institute of Chartered Accountants of Nigeria, an Associate
          Member of the Chartered Institute of Stockbrokers of Nigeria. An Associate of the
          Nigerian Institute of Management and also a Dealing Member of the Nigerian
          Stock Exchange. A Registered Dealing Clerk with the Securities and Exchange
          Commission. Also a Registered Member of Financial Reporting Council of Nigeria.
          Bimbola acquired 6 years accounting training experience from (Peat Marwick, Ani,
          Ogunde & Co.) now KPMG Audit, 6 years as Branch Accountant and 2 years as
          Investment Manager with NAL Merchant Bank Plc., 6 years as Licensed
          Stockbroker with Nigerian Stockbrokers Ltd (NSL) (a subsidy of NAL Merchant
          bank Plc.), He left as Deputy General manager (NSL) on 28h October,1998. He
          went into accounting practice before joining GTI Capital Ltd as Pioneer General
          Manager, Operations in January 2001. He was involved in staff recruitment,
          formation of policies and internal control system to guide the running of the firm.
          He also prepared an operational manual for the use of the firm, supervised all the
          units in the firm with emphasis on Accounts unit. He was also instrumental to the
          establishment of Akure office of the firm. In fact he ran the Ibadan office
          profitably for five months. He trades for the firm on the floors of the Exchange,
          represented the firm in different for a. He ran the firm alongside with the
          MD/CEO.
          
          He was Ag. Managing Director of Dynamic Portfolio Ltd for about a year before
          moving to Support Services Itd., as the General manager Investment, when the
          formal technically suspended operations. He also trades for Support Services on
          the floors of the Exchange as well.
          He joined Yuderb Investment and Securities Itd also as the pioneer General
          Manager Operations and Accounts the position he held until he moved to
          Topmost Securities Ltd in 2019 as Finance Director, while he doubles as Principal
          Partner in the firm of A. O. Olaniyi & Co (Chartered Accountants). He is currently a
          Consultant to the State of Osun. Also a Pioneer Member of Tax Audit Management
          (TAMA) in the State of Osun from year 2012 to date. All spanned over three decades.
          Otunba bimbola Olaniyi is widely traveled and married with children.`,
        link: "/HONTREASURER",
      },
      {
        id: 5,
        title: "Membership Secretary",
        name: "Ola Azeez, PhD, MBA, FCIPM, FCILG, FICMi, HRPL, FITD, HRPL.",
        imageUrl: "./assets/images/members/LCC_MEMBESHIP_SECRETARY.jpeg",
        desc:
          `Olasunkade Azeez has garnered high value net worth experiences as HR Leader, Business Process Improvement expert, Certified Coach/Mentor, Learning, Training and Development Leader, Senior Consultant, Member, Board of Directors, Board of Fellows, Governing Councils and Board of Trustees (BOT) across manufacturing, oil and gas, consulting, construction, real estate, aviation sectors, and Professional Institutes of the Nigerian economy in the last 3 decades. He started his working career with the Coca Cola Hellenic Bottling Company (CCHBC), immediately after graduation in 1991. He served the Company meritoriously in various capacities that prepared him for subsequent value-creating career path. \n \n Ola Azeez is an alumnus of Obafemi Awolowo University, Ile-Ife, University of Lagos, and Lagos State University, Ojo and he bagged Bachelor’s Degrees in Social Studies Education, Executive Diploma HRM, Postgraduate Diploma (PR), Nigerian Institute of Journalism, and MBA Marketing Management respectively. He holds Professional Doctor of Administration of Chartered Institute of Business Managers and Administrators, Delaware, USA. He is also an alumnus of many advanced management development programmes in Nigeria and abroad. He is an internationally certified Trainer in HR, Talent Management, Coaching, Mentoring, Performance Management Systems, Negotiation, Leadership, and Business Process Management System. Trained in Nigeria, Austria, Greece, Czech Republic, Ireland, Dublin, and Romania etc. He is the Managing Partner/CEO of REMOLAZ Management Consults, a division of Remolaz International (Nig) Ltd.\n \n He is currently the Group Head, Human Resources at Resort International Limited and subsidiaries. The Holding Company comprises of fortune Companies in the Aviation, Construction and Properties Development industries. He was a Senior Consultant and Team 
          Lead on HR Strategy and Change Management projects with Impact Business Consulting Services, a Business Strategic Partner with IBM, USA. He joined CITA Petroleum Group Executive Team – (an IATA Strategic Partner and multiple awards winning enterprise) as General Manager, Human Capital with over 2 decades of value adding business partnering experience in HRM, Sales Operations, and General Administration, across various sectors. In addition to Human Capital portfolio, he managed the Logistics subsidiary – JIL Transport as its Chief Operating Officer in addition to an oversight responsibility for the Commercial and Business Development functions of the Group and subsequently became the COO, CITA Organic
          Ltd, with key responsibility for non-aviation fuelling business of the group. He is a change
          management and strategic turnaround expert.
          \n\n
          As a thoroughbred professional, he is a Fellow and a past Vice President of the Nigerian
Institute of Training and Development, (NITAD), a Fellow, and a one-time, Governing Council
Member of Chartered Institute of Nigeria, (CIPM) and also a Fellow/Member of Board of
Trustees (BOT) of Change Management Institute International (ICMi). He is a Fellow and Lagos
State Coordinator of Nigeria Chapter of Chartered Institute of Leadership and Governance, USA.
He is also a governing Council member of Grand Plus College of Education, Ilorin Kwara State,
Nigeria and OML Accra, Ghana Republic.
\n\n
He is a columnist, writer and an author of two books and co-authored Learning Management
Systems and Facilitation (A cookbook for 21st century trainers) published in April 2022.
\n
Ola belongs to many other recreational, social and philanthropic associations. He is a member
of Association of International Lions Clubs, a onetime President, Ilupeju Lions Club and District
Officer under 404B2, Nigeria. A member of Ibadan Recreation Club, Omo Ajorosun Club, Ibadan
and member, Board of Trustees of Ibadan Borokinni Worldwide Forum (IBWF) among others.
He is the immediate past Vice Chairman of Badminton Section. He has served the Club in
various Committees of Council of the Club including Membership Screening, Staff and
Secretariat Staff Matters, 70th Planning Committee among others. \n\n
Ola is happily married with children.
          `,
        link: "/MEMBERSHIPSECRETARY",
      },
      {
        id: 6,
        title: "Hon, Social Secretary",
        name: "MR.OSEMOJE LATO",
        imageUrl: "./assets/images/members/LCC_SOCIAL_SECRETARY.JPEG",
        desc:
          `Osemoje Lato was born to the family of Mr. Roland & Mrs. Beatrice Lato of Benin City, Edo State of Nigeria in July 26th 1977. He attended St. Theresa’s Primary School, Marine Beach, Apapa from 1981 – 1986 and St. Gregory’s College Obalende, Ikoyi Lagos from 1987 – 1992 He graduated from Ambrose Alli University, Ekpoma, with a B.Sc in Economics in 2001.
          He started his working career with First October International in 2003 as an Operations manager before moving to Enownow as Client Service Manager. He also have a brief stint at Seed Media Limited as Head of New Media,His passion for marketing communications drove him to set up his media marketing communications agency in 2009 -  Chase Media Solutions a New media and marketing communications firm.
          His specialty is in the online/digital marketing space. He further diversified into other forms of marketing communications, Branding, Fabrication and Corporate communications.
          He is currently the MD/CEO of Chase Media Solutions Limited, Managing Partner, Sales & Business Director Visual Response Solutions Ltd.
          
          His wealth of experience is quite impressive having been involved in the following achievements:
          ·  The first to Launch Yahoo! Advert Campaign in Nigeria
          ·  Won the best Exhibition stand for Eterna Plc at the OTL Conference in  2018 & 2019
          His professional membership includes National Institute of Marketing of Nigeria. (NIMN)
          Change Maker President of Rotary Club Anthony. Also a member of Lagos Country Cub
          
          PAST SERVICE
          Member Club Media & Publicity Committee (2 Years)
          Member Club New Year Committee (2 Years)
          Entertainment Officer Snooker and Billiard Section (3 Years)
          Entertainment Officer Squash Section (2 Years)
          He is Married with a Child.
          `,
        link: "/HONSOCIALSECRETARY",
      },
      {
        id: 7,
        title: "SPORTS SECRETARY",
        name: "MR. BABAJIDE EGBELE",
        imageUrl: "./assets/images/members/babajideegbele.jpeg",
        desc: "Mr. Babajide Egbele is a renowned swimming consultant, seasoned professional Sports Administrator, and ex-National Athlete - Nigerian Rower and Medalist. He participated in the FISA sponsored University of Pretoria, South Africa elite Athletes programme targeting the World Rowing Championship in New Zealand and the 2012 London Olympic Games. He is FINA (World Aquatics Federation) Level 2 Coach and FISA ( World Rowing Federation) Level 2 Coach. The Sports Director of SIDA. an NGO dedicated to persons with mental and physical challenges. His astounding doggedness and courage as Swimming Section Captain was instrumental to today’s newly improved section of the club. As an Ex-Nigerian National Athlete, he was immediately recommended for FISA certification owing to his passion and dedication to the sport and its corresponding development in Lagos State and in Nigeria at large. He has represented the club in various tournaments as participant and coach with resounding successes attached. He was Team Coach, Swimming to the Special Olympics held in UAE, 2019 with medals attached. His current role as Sports Secretary of the Club was largely due to his performances technically and administratively. He’s the Co-founder of Aquatic Spots, largely involved in mentoring and tutoring young athletes,  A sports visionary and leader; he was part of the team that revitalized the Lagos Regatta Festival that seeks to identify and empower talents from riverine areas.",
        link: "/SPORTSSECRETARY",
      },
      {
        id: 8,
        title: "Hon. Council Member ",
        name: "Arc. Ashiru Tokunbo.",
        imageUrl: "./assets/images/members/LCC_COUNCI_MEMBER.jpeg",
        desc:
          `Arc Adetokunbo Ashiru. is the MD/CEO ASHTKB VENTURES LTD He holds a B.Tech. Architecture from the Rivers State University of Science and Technology, Port Harcourt, Rivers State and Master’s Degree in Architecture at the same university. He also holds Diploma in Computing at AutoCad Centre Rivers State as well as America Project Management British College UK (BSPM UK), Executive Masters Project Management-British College UK (BSPM College) and Advance Diploma Project Management British College UK (BSPM UK).  Before the inception of his company, he has served as Project Manager/Architect with UBAPROPERTIES now Afriland Properties Plc and also rose through the ranks at United Bank of Africa Plc to the post of Project Manager/Architect UBA Properties. \n\n
          Ashiru also enjoys sports and social activities, he is a member Proud Member of Lagos Country Club and have served in various capacity particularly at Aerobics Taekwondo section and other sub-committees of the club. He has a 1st Dan Black Belt on KUKKIWON World Taekwondo Tournament in Korea.      
          Architect Ashiru is married to Mrs Ekene Ashiru and are blessed with children.`,
          link: "/HONCOUNCILMEMBER",
      },
      {
        id: 9,
        title: "LEGAL ADVISER",
        name: "Barr. Sosanya Olubunmi Olaitan",
        imageUrl: "./assets/images/members/legaladvicer.jpeg",
        desc:
          `Barr. Sosanya Olubunmi Olaitan was Born on the 7th July, 1962. He bagged his West African School Certificate (WEAC) from the CMS Grammar School, and further attended the Prestigious University of Ife, (Now Obafemi Awolowo University, Ile-Ife) where he graduated with Bachelor of Laws, thereafter he attended Nigerian Law School after which he was called to Nigerian Bar in 1987. 
          Sosanya is the Principal Counsel Bunmi Sosanya and CO, a Law Firm he established January 1993 and still operates till date. Before the inception of his Law firm, he has served in the following organizations:
          •	Legal Practitioner Counsel - Abudu Akinyemi Ogunde Law Firm, 
          •	Legal Officer/Investment Executive/Company Secretary - Optimus Finance and Securities Limited, Lagos, 
          •	Junior Counsel - Tayo Osoba and CO, Legal Practitioners (NYSC), 
          •	Pupil Counsel - Banjo Solaru and CO, Barristers and solicitors, as well as some other vacation Jobs earlier in his career. 
          Sosanya also enjoys listening to Church/Choral music and singing same, he loves reading Legal and General books, meeting people/social interaction for purpose of gaining experience. He is Happily married with Children and a proud member of Lagos Country Club.`,
        link: "/LEGALADVISER",
      },
      {
        id: 10,
        title: "LCC GENERAL MANAGER",
        name: "---",
        imageUrl: "./assets/images/members/",
        desc: "---",
        link: "/GENERALMANAGER",
      },
      {
        id: 11,
        title: "CHAIRMAN, SNOOKER/BILLIARDS",
        name: "MR. KAYODE OLUWOLE",
        imageUrl: "./assets/images/members/LCC3.jpeg",
        desc: "Mr. Oluwole Olukayode, is a renowned Data and communications expert with an established multinational in the health management sector of the economy. He was Past Vice Chairman,  Past Captain and Past Committee Member Snooker/Billiards Section.He believes asking the right questions is necessary to move to the next level.",
        link: "/CHAIRMANSNOOKER",
      },
      {
        id: 12,
        title: "CHAIRMAN, SWIMMING SECTION",
        name: "MR. AYOKO OLUSOJI HENRY",
        imageUrl: "./assets/images/members/LCC2.JPG",
        desc: "My name is Ayoko Olusoji Henry. I am currently Chairman, Swimming Section. I am an astute entrepreneur with bias for export trade and merchandise. I have served in various capacities, such as E.O., Secretary and Vice Chairman respectively. Always ready to give service to humanity.",
        link: "/CHAIRMANSWIMMING",
      },
      {
        id: 13,
        title: "CHAIRMAN, TENNIS SECTION",
        name: "BABATUNDE A.M. FASHANU,  SAN",
        imageUrl: "./assets/images/members/Chairman_Tennis.jpeg",
        desc:
          "",
        link: "/CHAIRMANTENNIS",
      }, 
      {
        id: 14,
        title: "LCC CHAIRMAN DARTS",
        name: "PRINCE OWOLABI MUMUNI",
        imageUrl: "./assets/images/members/LCC_CHAIREMAN_DARTS.JPG",
        desc: "Owolabi Mumuni - Chairman Darts Section. Retired  Banker. Past Captain & Vice/Chairman of the Section Darts.",
        link: "/CHAIREMANDARTT",
      },
      {
        id: 15,
        title: "CHAIRMAN, BADMINTON SECTION",
        name: "MR. OLUFEMI AZEEZ",
        imageUrl: "./assets/images/members/CHAIRMAN_BADMINTON_SECTION.JPEG",
        desc:
          "",
        link: "/CHAIRMANBADMINTON",
      },
      {
        id: 16,
        title: "LCC CHAIRMAN TABLE TENNIS",
        name: "ALH. TUNJI LAWAL",
        imageUrl: "./assets/images/members/LCC_CHAIRMAN_TABLE_TENNIS.jpeg",
        desc:
          "Alhaji Olatunji Lawal, Chairman, Table Tennis Section. An established philanthropist, sport lover and enthusiast. MD/CEO, TOON CONSOLIDATED COMPANY LTD, (A major Exporter of Hibiscus Flower).President, Association of Hibiscus Flower Exporters of Nigeria (AHFEN), Chairman, Lagos State Table Tennis Association, Member: Nigeria Agro Export Committee (NAEC).",
        link: "/CHAIRMANTABLE",
      },
      {
        id: 17,
        title: "LCC Chairman Squash",
        name: "SIR 'SEMOORE BADEJO, FIIM",
        imageUrl: "./assets/images/members/chairman_squash.jpeg",
        desc:
          "Chairman, Squash Section, Lagos Country Club. A Member and dedicated Recreational & Social User of the Club. Squash is Life!",
        link: "/CHAIRMANSQUASH",
      },
      {
        id: 18,
        title: "LCC CHAIRMAN SCRABBLE",
        name: "Tunde Thani.",
        imageUrl: "./assets/images/members/LCC_CHAIRMAN_SCRABBLE.jpeg",
        desc:
          `Thunde Thani was born to the Thani-Oladunjoye family of Epe, Lagos State, on 10th August,
          1960.
          Thani had his Primary Education at St Michael Anglican Primary School (CMS) Epe, his
          Secondary Education at Ansar-ud-deen High School, Surulere, Lagos. He attended the
          prestigious University of Lagos where he obtained his BSC (Hons) Mass Communication with
          2
          nd Class Upper. He also holds an MSC in mass communication from the same university.
          An astute communicator, marketing and advertising expert. He is also a vivacious entrepreneur.
          He is a fellow of Advertising Practitioners Council (APCON), severally serve on its board and
          served as vice Chairman of the engine room of the body, the Advertising Standard Panel (ASP).
          with over 3 decades in marketing communications practice, Ivorian Thani held many Executive
          positions in the Association of Advertising Agencies of Nigeria (AAAN) and has handled multisectoral businesses/accounts including FMCG, Banking and Finance, Oil and Gas,
          Government/Politics, Pharmaceuticals and multinationals. He sits as the managing Director/CEO
          of the multi-award winning explicit group, a conglomerate of marketing communications
          companies.
          Thani a Philanthropist, recently facilitated a non-profit care Centre – Thani Oladunjoye Old
          People Care Centre, for senior citizens in his community, Epe, Lagos State. He has won many
          awards, both for his philanthropic gesture, social and political acumen including the prestigious
          University of Lagos, Alumni Association Golden Jubilee Distinguishes Alumni Award and
          Unilag Mass Communication Alumni Association UMCAA’s outstanding Alumni Entrepreneur
          in Mass Communication Award – both award he cherishes.
          It is not all work and no play. Thani is a Table Tennis buff and an ardent Scrabble player. He is
          currently the Chairman of Lagos Country Club Scrabble Section. He is also a prominent member
          of Great Summit Club.
          Tunde Thani is happily Married to Christiana Omobolanle Thani and blessed with children.`,
        link: "/CHAIRMANSCRABBLE",
      },
      {
        id: 19,
        name: "MR. ADETAYO ADEGBOYE",
        title: "LCC AEROBICS TAKEWONDO",
        imageUrl: "./assets/images/members/LCC_AEROBICS_TAKEWONDO.JPG",
        desc:
          "Chairman Aerobics/Takewondo  Astute Insurance professional with passion for human capital development,professionalism and teamwork .Loves reading physical exercise and social engagement.",
        link: "/AEROBICSTAKEWONDO",
      },
      // {
      //   id: 20,
      //   title: "CHAIRMAN, OTHER GAMES",
      //   name: "PSTR.  OYEYEMI TAIWO",
      //   imageUrl: "./assets/images/members/Chairman_other_games.jpeg",
      //   desc: "...",
      //   link: "",
      // },
      {
        id: 20,
        title: "CHAIRMAN, OTHER GAMES",
        name: "PRINCESS.  TOLA ADELE OLADOYIN",
        imageUrl: "./assets/images/members/Chairman_other_games.jpeg",
        desc: "She is an active member of our prestigious Club, she was the Past Sport Secretary of the Club, a past Captain of Darts Section and past Vice Chairman of Other Games section. She was a formal National Table Tannis player and Formal National Female Dart champion. A former South West Zonal Coordinator of Dart Federation of Nigeria (DFN).",
        link: "/OTHERGAME",
      },
    ],
  },
];

export default CARD_DATA;
