import React from "react";
import { Link } from "react-router-dom"
import routes from "../../routes/routes";

const HomeTeam = ({ title, imageUrl, name, desc , link}) => {
  return (
    <div className="col-md-4 col-sm-4 m-b30">
    <div className="dlab-box m-b30 team-1">
      <div className="dlab-media" style={{"display": "flex", "justifyContent": "center"}}>
        <img src={imageUrl} alt=""  style={{height: "270px",objectFit: "contain"}}/>
      </div>
      <div className="dlab-info text-center">
          <h3 className="dlab-title m-t20">
              <span className="text-primary text-uppercase">{name}</span>
          </h3>
          <p className="text-uppercase">{title}</p> 
          <span className="dlab-member-position">{`${desc.substring(0, 100)}...`}</span>    
      </div>
      <div className="p-a10">
        <ul className="dlab-social-icon">
          <li>
            <a href="#" className="fa fa-facebook site-button"></a>
          </li>
          <li>
            <a href="#" className="fa fa-twitter site-button"></a>
          </li>
          <li>
            <Link to={link} className="fa" style={{backgroundColor: "#CCA643", width: "80px", padding: "0px 5px"}}>View More</Link>
          </li>
        </ul>
      </div>
    </div>
    </div>
  );
};

export default HomeTeam;
