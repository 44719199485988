import React from "react";
import { PageHeader } from "../../../components";

export default function HONTREASURER() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_TREASURER.JPEG"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">OTUNBA ABIMBOLA OLANIYI</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                  Brief Resume of Otunba Abimbola Omotunde Olaniyi-FCA, ACS, AMNIM, FRCN
A fellow of the Institute of Chartered Accountants of Nigeria, an Associate
Member of the Chartered Institute of Stockbrokers of Nigeria. An Associate of the
Nigerian Institute of Management and also a Dealing Member of the Nigerian
Stock Exchange. A Registered Dealing Clerk with the Securities and Exchange
Commission. Also a Registered Member of Financial Reporting Council of Nigeria.
Bimbola acquired 6 years accounting training experience from (Peat Marwick, Ani,
Ogunde & Co.) now KPMG Audit, 6 years as Branch Accountant and 2 years as
Investment Manager with NAL Merchant Bank Plc., 6 years as Licensed
Stockbroker with Nigerian Stockbrokers Ltd (NSL) (a subsidy of NAL Merchant
bank Plc.), He left as Deputy General manager (NSL) on 28h October,1998. He
went into accounting practice before joining GTI Capital Ltd as Pioneer General
Manager, Operations in January 2001. He was involved in staff recruitment,
formation of policies and internal control system to guide the running of the firm.
He also prepared an operational manual for the use of the firm, supervised all the
units in the firm with emphasis on Accounts unit. He was also instrumental to the
establishment of Akure office of the firm. In fact he ran the Ibadan office
profitably for five months. He trades for the firm on the floors of the Exchange,
represented the firm in different for a. He ran the firm alongside with the
MD/CEO.

He was Ag. Managing Director of Dynamic Portfolio Ltd for about a year before
moving to Support Services Itd., as the General manager Investment, when the
formal technically suspended operations. He also trades for Support Services on
the floors of the Exchange as well.
He joined Yuderb Investment and Securities Itd also as the pioneer General
Manager Operations and Accounts the position he held until he moved to
Topmost Securities Ltd in 2019 as Finance Director, while he doubles as Principal
Partner in the firm of A. O. Olaniyi & Co (Chartered Accountants). He is currently a
Consultant to the State of Osun. Also a Pioneer Member of Tax Audit Management
(TAMA) in the State of Osun from year 2012 to date. All spanned over three decades.
Otunba bimbola Olaniyi is widely traveled and married with children.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
