const dartData = [
  {
    id: 1,
    alt: "gallery image",
    img: "/assets/images/section/Dart/d1.jpg",
  },
  {
    id: 2,
    alt: "gallery image",
    img: "/assets/images/section/Dart/d2.jpg",
  },
  {
    id: 3,
    alt: "gallery image",
    img: "/assets/images/section/Dart/d3.jpg",
  },
  {
    id: 4,
    alt: "gallery image",
    img: "/assets/images/section/Dart/d4.jpg",
  },
];
export default dartData;
