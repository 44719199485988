import React from "react";

export default function pageHeader({ title = "Title", page="none" }) {
  return (
    <>
      <div
        className="dlab-bnr-inr overlay-black-middle"
        style={{ backgroundImage: "url(../assets/images/banner/page-title-bg.jpg)" }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry" style={{textAlign: "center", paddingTop: "50px"}}>
            <h2 className="text-white text-uppercase">{title}</h2>
          </div>
        </div>
      </div>
      <div className="breadcrumb-row">
        <div className="container">
          <ul className="list-inline">
            <li><a href="/">Home</a></li>
            <li><a href="/">Section</a></li>
            <li>{page}</li>
          </ul>
        </div>
      </div>
    </>
  );
}
