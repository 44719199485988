import React from "react";
import { PageHeader } from "../../components";

export default function eventsDetails() {
  return (
    <div className="page-content">
      <PageHeader title="The inaugural V Bank Open Snooker" page="Event details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        <div class="section-full bg-white content-inner">
          <div class="container woo-entry">
            <div class="row">
              <div class="col-md-4">
                <div class="clearfix">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d126848.81976376878!2d3.3372391044290053!3d6.518440971475161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d6.4493912!2d3.4498355999999997!4m5!1s0x103b92159b76a9ab%3A0xaf8f3720edbd42bf!2slagos%20country%20club!3m2!1d6.5876472999999995!2d3.3564287!5e0!3m2!1sen!2sng!4v1613998267098!5m2!1sen!2sng"
                    style={{ border: 0, width: "100%", height: 400 }}
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                  <div class="dlab-divider bg-gray-dark">
                    <i class="icon-dot c-square"></i>
                  </div>
                  {/* <div class="widget recent-posts-entry">
                    <h5 class="widget-title">Related Events</h5>
                    <div class="widget-post-bx">
                      <div class="widget-post clearfix">
                        <div class="dlab-post-media">
                          {" "}
                          <img
                            src="./assets/images/blog/grid/pic1.jpg"
                            alt=""
                            width="200"
                            height="160"
                          />
                        </div>
                        <div class="dlab-post-info">
                          <div class="dlab-post-header">
                            <h6 class="post-title">
                              PHASE 2 REOPENING GUIDELINES UPDATE SEPTEMBER. 04,
                              2020
                            </h6>
                          </div>
                          <div class="dlab-post-meta">
                            <ul>
                              <li class="post-author">By Admin</li>
                              <li class="post-comment">
                                <i class="fa fa-comments"></i> 28
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="widget-post clearfix">
                        <div class="dlab-post-media">
                          {" "}
                          <img
                            src="./assets/images/blog/grid/pic2.jpg"
                            alt=""
                            width="200"
                            height="160"
                          />
                        </div>
                        <div class="dlab-post-info">
                          <div class="dlab-post-header">
                            <h6 class="post-title">
                              PHASE 2 REOPENING GUIDELINES UPDATE SEPTEMBER. 04,
                              2020
                            </h6>
                          </div>
                          <div class="dlab-post-meta">
                            <ul>
                              <li class="post-author">By Admin</li>
                              <li class="post-comment">
                                <i class="fa fa-comments"></i> 28
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="widget-post clearfix">
                        <div class="dlab-post-media">
                          <img
                            src="./assets/images/blog/grid/pic3.jpg"
                            alt=""
                            width="200"
                            height="160"
                          />
                        </div>
                        <div class="dlab-post-info">
                          <div class="dlab-post-header">
                            <h6 class="post-title">
                              PHASE 2 REOPENING GUIDELINES UPDATE SEPTEMBER. 04,
                              2020
                            </h6>
                          </div>
                          <div class="dlab-post-meta">
                            <ul>
                              <li class="post-author">By Admin</li>
                              <li class="post-comment">
                                <i class="fa fa-comments"></i> 28
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="widget-post clearfix">
                        <div class="dlab-post-media">
                          <img
                            src="./assets/images/blog/grid/pic4.jpg"
                            alt=""
                            width="200"
                            height="160"
                          />
                        </div>
                        <div class="dlab-post-info">
                          <div class="dlab-post-header">
                            <h6 class="post-title">
                              PHASE 2 REOPENING GUIDELINES UPDATE SEPTEMBER. 04,
                              2020
                            </h6>
                          </div>
                          <div class="dlab-post-meta">
                            <ul>
                              <li class="post-author">By Admin</li>
                              <li class="post-comment">
                                <i class="fa fa-comments"></i> 28
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="widget-post clearfix">
                        <div class="dlab-post-media">
                          <img
                            src="./assets/images/blog/grid/pic2.jpg"
                            alt=""
                            width="200"
                            height="160"
                          />
                        </div>
                        <div class="dlab-post-info">
                          <div class="dlab-post-header">
                            <h6 class="post-title">
                              PHASE 2 REOPENING GUIDELINES UPDATE SEPTEMBER. 04,
                              2020
                            </h6>
                          </div>
                          <div class="dlab-post-meta">
                            <ul>
                              <li class="post-author">By Admin</li>
                              <li class="post-comment">
                                <i class="fa fa-comments"></i> 28
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div class="col-md-8">
                <div class="dlab-media m-b30">
                  <a href="#">
                    <img
                      src="./assets/images/blog/grid/pic.jpeg"
                      alt=""
                      width="200"
                      height="160"
                    />
                  </a>
                </div>

                <div class="dlab-tabs border bg-tabs product-description">
                  <div class="tab-content">
                    <div class="tab-pane active">
                      <h3 class="m-t0">
                        The inaugural V Bank Open Snooker and Billiards
                        Tournament, which was hosted by Lagos Country Club
                        kicked off on March 30 at the Lagos Country Club’s
                        Snooker/Billiards Section, Ikeja, with cash prizes of up
                        to N600,000 on the line.
                      </h3>
                      <p class="m-b10">
                        Organisers of the Easter Opens gave Lagos a beautiful
                        tournament and it was no surprise that it took place at
                        the foremost Family Club in Nigeria, the Lagos Country
                        Club! Head, Marketing and Corporate Communications, VFD
                        Group, Efeturi Doghudje, said, “At VFD Microfinance
                        Bank, we are not only passionate about building
                        financial solutions, but being a key contributor to our
                        operating environment.
                      </p>
                      <p class="m-b10">
                        “This means that we take recreation activities seriously
                        and investing in sporting tournaments is our way of
                        encouraging people to take breaks to rejuvenate their
                        bodies because good health is priceless wealth.” The
                        two-week open tournament was held in partnership with
                        the Snooker and Billiards Section of the Lagos Country
                        Club, where the games held and has a total cash winnings
                        of up to N600,000. Registration for the event ended on
                        March 27, and this heralded the preliminary games that
                        produced the 32 contestants for the snookers/ Billiards
                        tournament, the preliminaries held for three days. The
                        tournament proper began March 30 and ended on April 10,
                        2021.
                      </p>
                      <p class="m-b10">
                        The game witnessed a huge and beautiful turn out as
                        professional Snooker/Billiards players from all over the
                        country participated in the competition. Members of the
                        Snooker/Billiards Section of the Club also took part in
                        the tournament which had the President of the Club, Arc.
                        Funmi Bamkole (a former No.1 seed at the Section)
                        participating. The tournament came to an end on Sunday,
                        April 10, 2021 with fun fare and glamour. Mr. Gideon won
                        the snooker’s 1st Prize slot while Mr. Wole Akintola won
                        the Billiard’s 1st Prize. There were lots of consolation
                        prizes such as the highest break points, second runner
                        up and so on. The President of the Club was joined by
                        some Council Members and Patrons of the Section to
                        present prizes to winners. This of course is a start,
                        more are coming. Kudos to the Management Committee of
                        the Section for a beautiful game . Enjoy the photo
                        speak.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
