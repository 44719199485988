import React from "react";
import Testimonial from "../testimonial";
import CARD_DATA from "./data"

class TestimonyDirectory extends React.Component {
  constructor() {
    super();

    this.state = {
        TeamSection: CARD_DATA,
      };
  }

  render() {
    return (
      <>
        {this.state.TeamSection.map(({ ...otherSectionProps }) => (
          <Testimonial {...otherSectionProps} />
        ))}   
      </>
    );
  }
}

export default TestimonyDirectory;
