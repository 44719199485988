import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { PageHeader } from "../../components";
import routes from "../../routes/routes";

export default function ContactUs() {
    const { register, handleSubmit, errors } = useForm();
    const mutation = useMutation(contactData => axios.post(routes.api.contactUs, contactData))
    const [message, setMessage] = useState("")

    const onSubmit = async (data) => {
        await mutation.mutate(data, {
            onSuccess: async (data) => {
                setMessage(["Message Sent Successfully"])
                // console.log(data.data.access_token);
            },
            onError: async (error) => {
                setMessage(["Message not sent. Try again"])
                // console.log(error.response);
            },
        })
        console.log("input >>>", data);

    };
    return (
        <div className="page-content">
            <PageHeader title="Contact Us" page="contact" />
            <div className="page-content">
                {/* <!-- Breadcrumb row END --> */}
                {/* <!-- contact area --> */}
                <div className="section-full content-inner-1 bg-white contact-style-1">
                    <div className="container">
                        <div className="row">
                            {/* <!-- Left part start --> */}
                            <div className="col-md-8">
                                <div className="p-a30 bg-gray clearfix m-b30 ">
                                    <h2>Send Message Us</h2>
                                    <div className="dzFormMsg"></div>
                                    <p className="text-success">{message[0]}</p>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <input type="hidden" value="Contact" name="dzToDo" />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">

                                                    <input
                                                        type="text"
                                                        id="member_id"
                                                        name="contactName"
                                                        className="form-control"
                                                        placeholder="Your Name"
                                                        ref={register({
                                                            required: "This input is required.",
                                                            minLength: {
                                                                value: 10,
                                                                message: "This input must exceed 10 characters",
                                                            },
                                                        })}
                                                    />

                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="contactName"
                                                        render={(messages) => {
                                                            console.log("messages", messages);
                                                            return messages
                                                                ? Object.entries(messages).map(([type, message]) => (
                                                                    <p className="text-danger">{message}</p>
                                                                ))
                                                                : null;
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Your email"
                                                        ref={register({
                                                            required: "This input is required.",
                                                            minLength: {
                                                                value: 10,
                                                                message: "This input must exceed 10 characters",
                                                            },
                                                        })}
                                                    />

                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="email"
                                                        render={(messages) => {
                                                            console.log("messages", messages);
                                                            return messages
                                                                ? Object.entries(messages).map(([type, message]) => (
                                                                    <p className="text-danger">{message}</p>
                                                                ))
                                                                : null;
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        id="phone_no"
                                                        name="phone_no"
                                                        className="form-control"
                                                        placeholder="Your Phone number"
                                                        ref={register({
                                                            required: "This input is required.",
                                                            minLength: {
                                                                value: 10,
                                                                message: "This input must exceed 10 characters",
                                                            },
                                                        })}
                                                    />

                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="phone_no"
                                                        render={(messages) => {
                                                            console.log("messages", messages);
                                                            return messages
                                                                ? Object.entries(messages).map(([type, message]) => (
                                                                    <p className="text-danger">{message}</p>
                                                                ))
                                                                : null;
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        id="subject"
                                                        name="subject"
                                                        className="form-control"
                                                        placeholder="Subject"
                                                        ref={register({
                                                            required: "This input is required.",
                                                            minLength: {
                                                                value: 10,
                                                                message: "This input must exceed 10 characters",
                                                            },
                                                        })}
                                                    />

                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="subject"
                                                        render={(messages) => {
                                                            console.log("messages", messages);
                                                            return messages
                                                                ? Object.entries(messages).map(([type, message]) => (
                                                                    <p className="text-danger">{message}</p>
                                                                ))
                                                                : null;
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <textarea
                                                        type="text"
                                                        id="message"
                                                        name="message"
                                                        className="form-control"
                                                        placeholder="Your Message..."
                                                        rows={6}
                                                        ref={register({
                                                            required: "This input is required.",
                                                            minLength: {
                                                                value: 10,
                                                                message: "This input must exceed 10 characters",
                                                            },
                                                        })}
                                                    ></textarea>

                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="message"
                                                        render={(messages) => {
                                                            console.log("messages", messages);
                                                            return messages
                                                                ? Object.entries(messages).map(([type, message]) => (
                                                                    <p className="text-danger">{message}</p>
                                                                ))
                                                                : null;
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                {/* <div className="form-group">
                                                    <div className="input-group">
                                                        <div
                                                            className="g-recaptcha"
                                                            data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                                            data-callback="verifyRecaptchaCallback"
                                                            data-expired-callback="expiredRecaptchaCallback"
                                                        ></div>
                                                        <input
                                                            className="form-control d-none"
                                                            style={{ display: "none" }}
                                                            data-recaptcha="true"
                                                            required
                                                            data-error="Please complete the Captcha"
                                                        />
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="col-md-12">
                                                <button
                                                    name="submit"
                                                    type="submit"
                                                    value="Submit"
                                                    className="site-button "
                                                >

                                                    <span>Submit</span>{" "}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <!-- Left part END --> */}
                            {/* <!-- right part start --> */}
                            <div className="col-md-4">
                                <div className="p-a30 m-b30 border-1 contact-area">
                                    <h2 className="m-b10">Quick Contact</h2>
                                    <p>
                                        If you have any questions simply use the following contact
                                        details.
                  </p>
                                    <ul className="no-margin">
                                        <li className="icon-bx-wraper left m-b30">
                                            <div className="icon-bx-xs bg-primary">
                                                {" "}
                                                <a href="/#" className="icon-cell">
                                                    <i className="fa fa-map-marker"></i>
                                                </a>{" "}
                                            </div>
                                            <div className="icon-content">
                                                <h6 className="text-uppercase m-tb0 dlab-tilte">
                                                    Address:
                        </h6>
                                                <p>
                                                    Joel Ogunnaike Street Off Mobolaji Bank Anthony Way
                                                    GRA Ikeja, Lagos.
                        </p>
                                            </div>
                                        </li>
                                        <li className="icon-bx-wraper left  m-b30">
                                            <div className="icon-bx-xs bg-primary">
                                                <a href="/#" className="icon-cell">
                                                    <i className="fa fa-envelope"></i>
                                                </a>
                                            </div>
                                            <div className="icon-content">
                                                <h6 className="text-uppercase m-tb0 dlab-tilte">
                                                    Email:
                        </h6>
                                                <p>contact@lagoscountryclub.net</p>
                                            </div>
                                        </li>
                                        <li className="icon-bx-wraper left">
                                            <div className="icon-bx-xs bg-primary">

                                                <a href="/#" className="icon-cell">
                                                    <i className="fa fa-phone"></i>
                                                </a>
                                            </div>
                                            <div className="icon-content">
                                                <h6 className="text-uppercase m-tb0 dlab-tilte">
                                                    PHONE
                        </h6>
                                                <p>+234708 860 5062</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="m-t20">
                                        <ul className="dlab-social-icon border dlab-social-icon-lg">
                                            <li className="m-r10">
                                                <a
                                                    href="http://www.facebook.com/lcc.ng"
                                                    className="fa fa-facebook bg-primary"
                                                >{''}</a>
                                            </li>
                                            <li>
                                                <a
                                                    href="http://twitter.com/lcc_ng"
                                                    className="fa fa-twitter bg-primary"
                                                >{''}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- right part END --> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {/* <!-- Map part start --> */}
                                <h2>Our Location</h2>
                                <iframe
                                    title="location"
                                    src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d126848.81976376878!2d3.3372391044290053!3d6.518440971475161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d6.4493912!2d3.4498355999999997!4m5!1s0x103b92159b76a9ab%3A0xaf8f3720edbd42bf!2slagos%20country%20club!3m2!1d6.5876472999999995!2d3.3564287!5e0!3m2!1sen!2sng!4v1613998267098!5m2!1sen!2sng"
                                    style={{ border: 0, width: "100%", height: 400 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                ></iframe>
                                {/* <!-- Map part END --> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- contact area  END --> */}
            </div>
        </div>
    );
}
