const swimmingData = [
  {
    id: 1,
    alt: "gallery image",
    img: "/assets/images/section/Swimming/sw1.jpg",
  },
  {
    id: 2,
    alt: "gallery image",
    img: "/assets/images/section/Swimming/sw2.jpg",
  },
  {
    id: 3,
    alt: "gallery image",
    img: "/assets/images/section/Swimming/sw3.jpg",
  },
  {
    id: 4,
    alt: "gallery image",
    img: "/assets/images/section/Swimming/sw4.jpg",
  },
  {
    id: 5,
    alt: "gallery image",
    img: "/assets/images/section/Swimming/sw5.jpg",
  },
  {
    id: 6,
    alt: "gallery image",
    img: "/assets/images/section/Swimming/sw6.jpg",
  },
  {
    id: 7,
    alt: "gallery image",
    img: "/assets/images/section/Swimming/sw7.jpg",
  },
];
export default swimmingData;
