import React from "react";
import { PageHeader } from "../../../components";

export default function CHAIRMANTABLE() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_CHAIRMAN_TABLE_TENNIS.jpeg"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">ALH. TUNJI LAWAL</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                    Alhaji Olatunji Lawal, Chairman, Table Tennis Section. An
                    established philanthropist, sport lover and enthusiast.
                    MD/CEO, TOON CONSOLIDATED COMPANY LTD, (A major Exporter of
                    Hibiscus Flower). President, Association of Hibiscus Flower
                    Exporters of Nigeria (AHFEN), Chairman, Lagos State Table
                    Tennis Association, Member: Nigeria Agro Export Committee
                    (NAEC).
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
