import React from "react";
import { PageHeader } from "../../../components";

export default function LEGALADVISER() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/legaladvicer.jpeg"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">Barr. Sosanya Olubunmi Olaitan</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                  Barr. Sosanya Olubunmi Olaitan was Born on the 7th July, 1962. He bagged his West African School Certificate (WEAC) from the CMS Grammar School, and further attended the Prestigious University of Ife, (Now Obafemi Awolowo University, Ile-Ife) where he graduated with Bachelor of Laws, thereafter he attended Nigerian Law School after which he was called to Nigerian Bar in 1987. 
Sosanya is the Principal Counsel Bunmi Sosanya and CO, a Law Firm he established January 1993 and still operates till date. Before the inception of his Law firm, he has served in the following organizations:
<br></br>•	Legal Practitioner Counsel - Abudu Akinyemi Ogunde Law Firm, <br></br>
•	Legal Officer/Investment Executive/Company Secretary - Optimus Finance and Securities Limited, Lagos, <br></br>
•	Junior Counsel - Tayo Osoba and CO, Legal Practitioners (NYSC), <br></br>
•	Pupil Counsel - Banjo Solaru and CO, Barristers and solicitors, as well as some other vacation Jobs earlier in his career. <br></br>
Sosanya also enjoys listening to Church/Choral music and singing same, he loves reading Legal and General books, meeting people/social interaction for purpose of gaining experience. He is Happily married with Children and a proud member of Lagos Country Club.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
