const takwandoData = [
  {
    id: 1,
    alt: "gallery image",
    img: "/assets/images/section/Takwando/tk1.jpg",
  },
  {
    id: 2,
    alt: "gallery image",
    img: "/assets/images/section/Takwando/tw2.jpg",
  },
  {
    id: 3,
    alt: "gallery image",
    img: "/assets/images/section/Takwando/tw3.jpg",
  },
  {
    id: 4,
    alt: "gallery image",
    img: "/assets/images/section/Takwando/tw4.jpg",
  },
  {
    id: 5,
    alt: "gallery image",
    img: "/assets/images/section/Takwando/tw5.jpg",
  },
  {
    id: 6,
    alt: "gallery image",
    img: "/assets/images/section/Takwando/tw6.jpg",
  },
  {
    id: 7,
    alt: "gallery image",
    img: "/assets/images/section/Takwando/tw7.jpg",
  },
  {
    id: 8,
    alt: "gallery image",
    img: "/assets/images/section/Takwando/tw8.jpg",
  },
  {
    id: 9,
    alt: "gallery image",
    img: "/assets/images/section/Takwando/tw9.jpg",
  },
  {
    id: 10,
    alt: "gallery image",
    img: "/assets/images/section/Takwando/tw10.jpg",
  },
];
export default takwandoData;
