import React from "react";
import { PageHeader, TeamMember } from "../../components";
import TennisGallery from "./directory/tennis";
import style from "./style.module.css";

export default function Tennis() {
  return (
    <div className="page-content">
      <PageHeader title="Tennis" page="tennis" />
      {/* <!-- Content --*/}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div class="section-fullbg-gray content-inner bg-gray ">
            <div class="container">
              <div class="row">
                <div class="col-md-5 col-sm-12 hidden-sm">
                  <img src="/assets/images/gallery/t2.jpg" alt="" class="m-b30" />
                </div>
                <div class="col-md-7 col-sm-12">
                  <h3 class="h2">WELCOME TO TENNIS</h3>
                  <div class="dlab-separator bg-primary"></div>
                  <h4 class="font-weight-3 00">
                    The game of Tennis makes you feel better and improves your
                    mood. Whether you play recreationally or competitively,
                    Tennis as a sport gives optimal full body and brain workout
                    always. It’s fun and its beginning of quality life.
                  </h4>
                  <p>
                    The Tennis Section of Lagos Country Club is the largest,
                    busiest and by far the most developed section of the club.
                    With registered membership of 442 people, the section
                    continues to receive an ever increasing proportion of new
                    entrants into the club yearly. It occupies an area which
                    accounts for well over a quarter of total land space of the
                    club. It is an environment that is well planned and that has
                    remained well-groomed over the years to the delight of
                    members and visitors. It has two main buildings, a section
                    building and an international standard tennis viewing
                    pavilion. The section can accommodate 562 comfortably seated
                    Tennis enthusiasts during any tournament.
                  </p>
                  <p>
                    The pride of the section is of course the playing courts.
                    The section has six courts – two hard courts, four clay
                    courts, and a standard practice court. The clay is a unique
                    turf because of the slower moving nature of the balls, its
                    softer on the kneels and tends to encourage members to play
                    more and for longer years. The only clay court in Lagos and
                    of course the best maintained in West Africa. The Section
                    have over the years hosted some of the most celebrated
                    national and ATP international clay court tournaments played
                    in Nigeria like John Player Classics, Nigerian Open, Rainoil
                    National Open, Quadrangular games and other ATP point
                    awarding matches.
                  </p>
                  <p>
                    The section is ever a beehive of activities as members and
                    visitors get engaged with several tournaments during the
                    course of the year. From annual title sponsored tournaments
                    to American tournament to impromptu and special occasions’
                    tournaments. The Tennis Section is busy all year round. No
                    wonder Tennis Section is the In fact it is the heartbeat of
                    the club.
                  </p>
                  <p>
                    Some of our notable tournaments that holds regularly
                    include:
                    <ul className={`${style.marginVertical}`}>
                      <li>
                        Prince Joseph Adewunmi Ademuluyi Annual Tennis
                        Tournament (for Children)
                      </li>
                      <li>Annual Ladder Challenge</li>
                      <li>Mentor American Tournament</li>
                      <li>Annual Snooker Championship</li>
                      <li>9Mobile Snooker Festival</li>
                      <li>Babatunde Fashanu Annual Tournament</li>
                      <li>Rasky Gbinigie</li>
                      <li>Ball Boys American Tournament</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Team member --> */}
           {/* <!-- Gallery section --> */}
           <TennisGallery />
          {/* <!--End Gallery section --> */}
          <TeamMember 
          name="BABATUNDE A.M. FASHANU, SAN"
          title="Chairman Tennis Sections."
          img="./assets/images/members/Chairman_Tennis.jpeg" />
          {/* <!-- Team member --> */}
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
