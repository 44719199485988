import React from "react";

const Menu = ({ items }) => {
  return (
    <div className="section-center">
      {items.map((menuItem) => {
        const { id, title, img } = menuItem;
        return (
          
              <li key={id}
                className="card-container col-lg-4 col-md-4 col-sm-6 col-xs-6 "
              >
                <div className="dlab-box dlab-gallery-box m-b0">
                  <div className=" dlab-img-overlay1 dlab-img-effect rotate" style={{height: "270px"}}>
                    <a href="">
                      <img src={img} alt={title} />
                    </a>
                    <div className="overlay-bx">
                      <div className="overlay-icon">
                        <a href={img} className="mfp-link">
                          <i className="fa fa-picture-o icon-bx-xs"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
        );
      })}
    </div>
  );
};

export default Menu;
