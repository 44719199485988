import React from "react";
import { PageHeader } from "../../components";

export default function NewHanding() {
  return (
    <div className="page-content">
      <PageHeader
        title="HANGING OF PICTURES OF TABLE TENNIS"
        page="Event details"
      />
      {/* <!-- Content --> */}
      <div className="page-content">
        <div class="section-full bg-white content-inner">
          <div class="container woo-entry">
            <div class="row">
              <div class="col-md-4">
                <div class="clearfix">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d126848.81976376878!2d3.3372391044290053!3d6.518440971475161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d6.4493912!2d3.4498355999999997!4m5!1s0x103b92159b76a9ab%3A0xaf8f3720edbd42bf!2slagos%20country%20club!3m2!1d6.5876472999999995!2d3.3564287!5e0!3m2!1sen!2sng!4v1613998267098!5m2!1sen!2sng"
                    style={{ border: 0, width: "100%", height: 400 }}
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                  <div class="dlab-divider bg-gray-dark">
                    <i class="icon-dot c-square"></i>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="dlab-media m-b30">
                  <a href="#">
                    <img
                      src="/assets/images/section/tableTennis/tt3.jpg"
                      alt=""
                      width="200"
                      height="160"
                    />
                  </a>
                </div>

                <div class="dlab-tabs border bg-tabs product-description">
                  <div class="tab-content">
                    <div class="tab-pane active">
                      <h3 class="m-t0">
                        Friday, May 21 2021 was unique in the life of Table
                        Tennis Section. It was the day earmarked for the hanging
                        of picture of both the newly elected Chairman of the
                        Section, Alhaji Tunji Lawal and the newly appointed
                        Patron of the Section, Dr. Bode Ogunbanjo. The double
                        celebration witnessed a live band and great
                        entertainment, but with all the Covid 19 protocol in
                        full force.
                      </h3>
                      <p class="m-b10">
                        It was a rare gathering, as the Club’s Council was well
                        represented, led by the indefatigable President, Arc.
                        Funmi Bamkole. Dignitaries and sports enthusiasts
                        including the Table Tennis international and Olympic
                        Champion, Bose Kaffo were also on hand to share in the
                        joy. Dr. Ogunbanjo, a well decorated medical
                        practitioner and also a former Chairman of the Section,
                        is joining the clan of patrons populated by eminent
                        Nigerians such as Dr. Olatunji Bankole, Engr. Robert
                        Okeleke, Alhaji Ismaila Aderemi Bello and Chief (Mrs.)
                        Catherine Kehinde George. Fifteen past chairmen have
                        their pictures displayed in the Picture gallery with the
                        picture of Mr. E. A. Odukoya who was Chairman between
                        1979 and 1980 leading the pack.
                      </p>
                      <p class="m-b10">
                        Hanging of pictures is an entrenched tradition in Lagos
                        Country Club, a ritual that is given the devotion of a
                        mini jamboree. It is an event where an immediate past or
                        a new officer is recognized and celebrated. For the
                        event of May 21, the picture of Engr. Charles Osuntuyi
                        which had adorned the wall of the section’s lounge for
                        the past three years, had to move to the “Hall of Fame,”
                        the gallery harboring pictures of past chairmen while
                        the picture of the new Chairman took its rightful place.
                        Same ceremony is performed in other sections. But more
                        significance is given to the offices of the President,
                        the Vice President, the Secretary General and the
                        Membership Secretary of the Club.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
