import React from "react";

const Patron = () => (
  <>
    <div className="col-md-12">
      <div className="dlab-box m-b30 team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/Sanwo.jpg"
            alt="Buhari"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">Mr. Babajide Sanwo-Olu</h4>
          <p className="dlab-member-position">
            Executive Governor of Lagos State, Lagos State Government
            Secretariat, Alausa, Ikeja.
          </p>
        </div>
      </div>
    </div>
  </>
);

export default Patron;
