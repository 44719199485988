import React from "react";
import { PageHeader } from "../../components";
import routes from "../../routes/routes";

export default function ClubNews() {
  return (
    <div className="page-content">
      <PageHeader title="Club News" page="News" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        <div className="section-full bg-white content-inner-1">
          <div className="container">
              {/* <!-- Blog half image --> */}
          <div className="blog-post blog-md clearfix date-style-4 event-post ">
              <div className="dlab-post-media dlab-img-effect rotate">
                <img src="/assets/images/section/tableTennis/tt3.jpg" alt="" />
              </div>
              <div className="dlab-post-info">
                <div className="dlab-post-title ">
                  <h3 className="post-title">
                    HANGING OF PICTURES OF TABLE TENNIS CHAIRMAN AND PATRON
                  </h3>
                </div>
                <div className="dlab-post-meta ">
                  <ul>
                    <li className="post-date">
                      <i className="fa fa-calendar"></i>
                      <strong>21</strong>
                      <span> May</span>
                    </li>
                  </ul>
                </div>
                <div className="dlab-post-text">
                  <p>
                    Friday, May 21 2021 was unique in the life of Table Tennis
                    Section. It was the day earmarked for the hanging of picture
                    of both the newly elected Chairman of the Section, Alhaji
                    Tunji Lawal and the newly appointed Patron of the Section,
                    Dr. Bode Ogunbanjo. The double celebration witnessed a live
                    band and great entertainment, but with all the Covid 19
                    protocol in full force.
                  </p>
                </div>
                <div className="dlab-post-readmore">
                  <a
                    href={routes.public.newDetail_1}
                    title="READ MORE"
                    rel="bookmark"
                    className="site-button-link"
                  >
                    READ MORE<i className="fa fa-angle-double-right"></i>
                  </a>
                </div>
              </div>
            </div>
            
            <div className="blog-post blog-md clearfix date-style-4 event-post ">
              <div className="dlab-post-media dlab-img-effect rotate">
                <img src="./assets/images/blog/grid/COVID-19.jpg" alt="" />
              </div>
              <div className="dlab-post-info">
                <div className="dlab-post-title ">
                  <h3 className="post-title">
                    ADHERENCE TO COVID-19 PROTOCOLS May 11, 2021
                  </h3>
                </div>
                <div className="dlab-post-meta ">
                  <ul>
                    <li className="post-date">
                      <i className="fa fa-calendar"></i>
                      <strong>11 </strong>
                      <span> May</span>
                    </li>
                  </ul>
                </div>
                <div className="dlab-post-text">
                  <p>
                    Following the Federal Government’s announcement yesterday
                    10th May, 2021 on restriction of mass gatherings to prevent
                    virulent COVID-19 variants and also control the spread of
                    the virus, members are enjoined to adhere STRICTLY at all
                    times to the underlisted Protocols within the Club’s
                    premises:
                  </p>
                </div>
                <div className="dlab-post-readmore">
                  <a
                    href={routes.public.protocolCovid}
                    title="READ MORE"
                    rel="bookmark"
                    className="site-button-link"
                  >
                    READ MORE<i className="fa fa-angle-double-right"></i>
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- Blog half image END --> */}
            {/* <!-- Pagination  --> */}
            <div className="pagination-bx clearfix ">
              <ul className="pagination">
                <li className="previous">
                  <a href="#">
                    <i className="fa fa-angle-double-left"></i>
                  </a>
                </li>
                <li className="active">
                  <a href="#">1</a>
                </li>
                <li>
                  <a href="#">2</a>
                </li>
                <li>
                  <a href="#">3</a>
                </li>
                <li className="next">
                  <a href="#">
                    <i className="fa fa-angle-double-right"></i>
                  </a>
                </li>
              </ul>
            </div>
            {/* <!-- Pagination END --> */}
          </div>
        </div>
      </div>
    </div>
  );
}
