import React from "react";
import { PageHeader } from "../../../components";

export default function CHAIRMANSNOOKER() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC3.jpeg"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">MR. KAYODE OLUWOLE</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                    Mr. Oluwole Olukayode, is a renowned Data and communications
                    expert with an established multinational in the health
                    management sector of the economy. He was Past Vice Chairman,
                    Past Captain and Past Committee Member Snooker/Billiards
                    Section. He believes asking the right questions is necessary
                    to move to the next level.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
