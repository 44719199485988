import React from "react";
import { PageHeader } from "../../../components";

export default function VICEPRESIDENT() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_VICE_PRESIDENT.jpeg"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">ENGR OLATUNJI AMOSU</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                  Engr Olatunji Amosu is the MD/CEO Boltons Nigeria Limited, an international outfit. He holds a Bachelor’s Degree in Public Administration from the Lagos State University, and Diploma in Biomedical Communications as well as Diploma in Computer Elect from University of Lagos, he also has a Cert in Technical Training from Terumo Europe NV, Belgium.<br></br>
                  Engr Tunji Amosu is married with children and enjoys sports and social activities. He is a member of several Social Clubs and Societies and have served in various capacity in the Club, particularly Darts section where he was Entertainment officer, Secretary, Vice Chairman and Chairman.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
