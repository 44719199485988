import React from "react";
import AboutTeam from "../../AboutTeam";
import CARD_DATA from "./data"

class TeamDirectory extends React.Component {
  constructor() {
    super();

    this.state = {
        TeamSection: CARD_DATA,
      };
  }

  render() {
    return (
      <>
        {this.state.TeamSection.map(({ ...otherSectionProps }) => (
          <AboutTeam {...otherSectionProps} />
        ))}   
      </>
    );
  }
}

export default TeamDirectory;
