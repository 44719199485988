const squashData = [
  {
    id: 1,
    alt: "gallery image",
    img: "/assets/images/section/Squash/sq1.jpg",
  },
  {
    id: 2,
    alt: "gallery image",
    img: "/assets/images/section/Squash/sq2.jpg",
  },
  {
    id: 3,
    alt: "gallery image",
    img: "/assets/images/section/Squash/sq3.jpg",
  }
];
export default squashData;
