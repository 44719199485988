import React from "react";
import "./videoHeader.css";

export default function VideoHeader() {
  return (
    <>
      <div className="hero_header">
        <div className="overlay"></div>
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="/assets/images/main-slider/lcc1.mp4" type="video/mp4" />
        </video>
        <div className="container h-100 hero-text">
          <div className="d-flex h-100 text-center align-items-center">
            <div className="w-100 text-white">
              <h1 className="display-3">LAGOS COUNTRY CLUB</h1>
              <p className="lead mb-0 text-center align-items-center">
                Lagos Country Club is a private, family country club devoted to
                the social well- being of it's members. Founded in 1949, LCC
                maintains an excellent multi-sports history and is located in
                Ikeja, Lagos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
