import React from "react";
import { PageHeader, TeamMember } from "../../components";
import SquashGallery from "./directory/squash";
import style from "./style.module.css";
export default function Squash() {
  return (
    <div className="page-content">
      <PageHeader title="Squash " page="squash"/>
      {/* <!-- Content -> */}
      <div className="page-content">
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          {/* <!-- Our Awesome Services --> */}
          <div className="section-full bg-gray content-inner ">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center section-head mamber-dec">
                  <h3 className="h2">WELCOME TO SQUASH</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <div className="clear"></div>
                  <h5 class="font-weight-3 m-b30">
                    The Squash Section of the Lagos Country Club welcomes you.
                    We offer a relaxed environment with facilities to delight
                    you. Do join us...
                  </h5>
                  {/* <a href="/#" className="site-button">Learn More</a> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- About Company END --> */}
          <SquashGallery/> 
          {/* <!-- Team member --> */}
          <TeamMember 
          name="SIR 'SEMOORE BADEJO, FIIMSIR 'SEMOORE BADEJO, FIIM"
          title="LCC Chairman Squash"
          img="./assets/images/members/chairman_squash.jpeg" />
          {/* <!-- Team member --> */}
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
