import HomePage from "./home/HomePage";
import AboutPage from "./about/AboutPage";
import Management from "./sections/management";
import LogIn from "./formPage/LogIn";
import ClubNews from "./clubNews/clubNews";
import NewHanding from "./clubNews/newDetails_1"
import EventsHanding from "./clubEvents/eventsDetails_1"
import ClubEvents from "./clubEvents/clubEvents";
import eventsDetails from "./clubEvents/eventsDetails";
import protocolCovid from "./clubEvents/protocolCovid";
import ContactUs from "./contactUs/contactUs";
import Tennis from "./sections/tennis";
import Snooker from "./sections/snooker";
import Dart from "./sections/dart";
import Swimming from "./sections/swimming";
import Taekwondo from "./sections/taekwondo";
import Scrabble from "./sections/scrabble";
import Badminton from "./sections/badminton";
import Squash from "./sections/squash";
import OtherGames from "./sections/other_games";
import ResetPassword from "./auth/resetPassword";
import ChangePassword from "./auth/changePassword";
import TableTennis from "./sections/tableTennis";

export const Home = HomePage
export const About = AboutPage
export const Managements = Management
export const News = ClubNews
export const NewHandings = NewHanding
export const EventsHandings = EventsHanding
export const Events = ClubEvents
export const Details = eventsDetails
export const protocolCovids = protocolCovid
export const Contact = ContactUs
export const Login = LogIn
export const SectionTennis = Tennis
export const SectionSnooker = Snooker
export const SectionDart = Dart
export const SectionSwimming = Swimming
export const SectionTaekwondo = Taekwondo
export const SectionScrabble = Scrabble
export const SectionBadminton = Badminton
export const SectionSquash = Squash
export const SectionOtherGames = OtherGames
export const PasswordReset = ResetPassword
export const PasswordChange = ChangePassword
export const TTennis = TableTennis