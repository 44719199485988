import React from "react";
import {  PageHeader, TeamMember } from "../../components";
import ScrabbleGallery from "./directory/scrabble";

export default function Scrabble() {
  return (
    <div className="page-content">
      <PageHeader title="Scrabble "  page="scrabble"/>
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- contact -> */}
        <div className="content">
          {/* <!-- About Company --> */}
          {/* <!-- Our Awesome Services --> */}
          <div className="section-full bg-gray content-inner ">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center section-head mamber-dec">
                  <h3 className="h2">WELCOME TO SCRABBLE</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <div className="clear"></div>
                  <h5 class="font-weight-3 m-b30">
                    After the Hullabaloo in the Club occasioned by the proposed
                    merger of the section with the Other Games sections, the
                    Scrabble section has since settled down to work with the new
                    Excos.
                  </h5>
                  {/* <a href="/#" className="site-button">Learn More</a> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- About Company END --> */}
          <ScrabbleGallery/>
          {/* <!-- Team member --> */}
          <TeamMember 
          name="Tunde Thani."
          title="LCC CHAIRMAN SCRABBLE"
          img="./assets/images/members/LCC_CHAIRMAN_SCRABBLE.jpeg" />
          {/* <!-- Team member --> */}
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
