import React from "react";
import { PageHeader } from "../../components";

export default function protocolCovid() {
  return (
    <div className="page-content">
      <PageHeader
        title="Recent protocol on COVID 19"
        page="News on COVID 19"
      />
      {/* <!-- Content --> */}
      <div className="page-content">
        <div class="section-full bg-white content-inner">
          <div class="container woo-entry">
            <div class="row">
              <div class="col-md-4">
                <div class="clearfix">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d126848.81976376878!2d3.3372391044290053!3d6.518440971475161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d6.4493912!2d3.4498355999999997!4m5!1s0x103b92159b76a9ab%3A0xaf8f3720edbd42bf!2slagos%20country%20club!3m2!1d6.5876472999999995!2d3.3564287!5e0!3m2!1sen!2sng!4v1613998267098!5m2!1sen!2sng"
                    style={{ border: 0, width: "100%", height: 400 }}
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                  <div class="dlab-divider bg-gray-dark">
                    <i class="icon-dot c-square"></i>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
              <div class="dlab-media m-b30">
                  <a href="#">
                    <img
                      src="./assets/images/blog/grid/COVID-19.jpg"
                      alt=""
                      width="200"
                      height="160"
                    />
                  </a>
                </div>
                <div class="dlab-tabs border bg-tabs product-description">
                  <div class="tab-content">
                    <div class="tab-pane active">
                      <h3 class="m-t0">
                        ADHERENCE TO COVID-19 PROTOCOLS May 11, 2021
                      </h3>
                      <p class="m-b10">
                        Following the Federal Government’s announcement
                        yesterday 10th May, 2021 on restriction of mass
                        gatherings to prevent virulent COVID-19 variants and
                        also control the spread of the virus, members are
                        enjoined to adhere STRICTLY at all times to the
                        underlisted Protocols within the Club’s premises:
                      </p>
                      <h3>COVID -19 PROTOCOLS</h3>
                      <p>
                        1.Members’ temperatures will be checked at the Gate and{" "}
                        <strong>Reception</strong>. Members with readings above
                        37ºC shall not be allowed access into the Club.
                        <br />
                        2.<strong>Use of FACE-MASK IS COMPULSORY</strong> within
                        the Club’s premises.
                        <br />
                        3. Members must wash their hands at the wash-hand basins
                        provided beside the entrance and/or sanitize their hands
                        before entering the Club. Regular and thorough washing
                        of hands with soap under running water or use of
                        alcohol-based hand-sanitizer is encouraged.
                        <br />
                        4. Avoid touching yours eyes, nose and mouth with
                        unwashed hands.
                        <br />
                        5. Cover your nose and mouth with your bent elbow or
                        tissue when you cough or sneeze. Dispose of the tissue
                        immediately.
                        <br />
                        6.Avoid shaking hands, hugging and any other form of
                        physical contact as much as possible.
                        <br />
                        7. Maintain two (2) meters distance at all times in line
                        with the approved Social Distancing Protocol.
                        <br />
                        8. Members are required to obey all floor and seat
                        markings for social distancing.
                        <br />
                        9. Kindly stay at home if you feel unwell or have any
                        other underlying aliments.
                        <br />
                        10. Remain in self-isolation for 14 days if you have
                        just returned from International travel before coming to
                        the Club. 11. Club shall close by 11pm daily with the
                        last order taken at 10.30pm.
                      </p>
                      <span>Thank you.</span>
                      <h3 class="m-t0">
                        Adegboyega Oduwole
                        <br /> 
                        Hon. Secretary General
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
