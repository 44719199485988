import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import secureLS from "../../../encryption/secureLs";
import routes from "../../../routes/routes";

export default function SignIn() {
    const { register, handleSubmit, errors } = useForm();
    const mutation = useMutation(signInData => axios.post(routes.api.login, signInData))
    const [message, setMessage] = useState("")

    // function useQuery() {
    //     return new URLSearchParams(this.props.location.search).get("your_query_param_key");
    // }
    const onSubmit = async (data) => {
        await mutation.mutate(data, {
            onSuccess: async (data) => {
                setMessage(["Login Successful"])
                // console.log(data.data.access_token);
                // console.log(process.env.REACT_APP_NOT_SECRET_CODE);


                await secureLS.set("authToken", data.data.access_token)

                // new URLSearchParams().get("your_query_param_key");
                window.location.href = routes.dashboard.index + `/l/${localStorage.getItem("authToken")}`
            },
            onError: async (error) => {
                setMessage(["Member Id or password incorrect"])

                // console.log(error.response);
            },
            // onSettled: (data, error, variables, context) => {
            //     // I will fire second!

            //   },
        })
        console.log("input >>>", data);

    };
    return (
        <>
            <div className="container" style={{ margin: 35 }}>
                <div className="row">
                    <div className="col-md-7 p-lr40 p-t30 p-b20 clearfix mack-an-appointment black border-1">
                        <div className="card card-body">
                            <h1 className="text-center mb-3">
                                <i className="fas fa-sign-in-alt"></i>
                                Login
                            </h1>
                            <p className="text-success">{message[0]}</p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label htmlFor="member_id">Member Id</label>
                                    <input
                                        type="member_id"
                                        id="member_id"
                                        name="member_id"
                                        className="form-control"
                                        placeholder="Enter member_id"
                                        ref={register({
                                            required: "This input is required.",
                                            minLength: {
                                                value: 2,
                                                message: "This input must exceed 2 characters",
                                            },
                                        })}
                                    />

                                    <ErrorMessage
                                        errors={errors}
                                        name="member_id"
                                        render={(messages) => {
                                            console.log("messages", messages);
                                            return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                    <p className="text-danger">{message}</p>
                                                ))
                                                : null;
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="Enter Password"
                                        ref={register({
                                            required: "This input is required.",
                                            minLength: {
                                                value: 6,
                                                message: "This input must exceed 6 characters",
                                            },
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        render={({ message }) => {
                                            console.log("message", message);
                                            return <p className="text-danger">{message}</p>
                                        }}
                                    />
                                </div>
                                <div className="col-md-12 text-center">
                                    <button
                                        name="submit"
                                        type="submit"
                                        className="site-button"
                                        style={{ fontSize: 20, fontWeight: 500 }}
                                    >
                                        Login
                                    </button>
                                </div>
                            </form>
                            <div className="pt-4">
                                No Account?
                                <a href={routes.public.contact} className="text-success">
                                    Register
                                </a>
                            </div>
                            <div className="pt-4">
                                <Link to={routes.public.resetPassword} className="text-success">
                                    Reset password
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
