import React from "react";

export default function TestimonialSecton({ title, imageUrl, desc }) {
  return (
    <>
      <div className="item">
        <div className="testimonial-2 testimonial-bg">
          <div className="testimonial-text">
            <p>
              The Club offers a structured Taekwondo/Aerobics programme for
              beginner and intermediate levels. It is one Section that has
              enhanced the family values of the Club.
            </p>
          </div>
          <div className="testimonial-detail clearfix">
            <div className="testimonial-pic quote-left radius shadow">
              <img
                style={{ height: "70px" }}
                src={imageUrl}
                width="100"
                height="100"
                alt=""
              />
            </div>
            <strong className="testimonial-name">{title}</strong>
            <span className="testimonial-position">Members</span>
          </div>
        </div>
      </div>
    </>
  );
}
