import React from "react";
import routes from "../../routes/routes";

export default function NavbarComponent() {
  return (
    <>
      <header className="row site-header header-style-5">
        {/* <!-- main header --> */}
        <div className="main-bar-wraper">
          <div className="main-bar clearfix ">
            <div className="container clearfix">
              {/* <!-- website logo --> */}
              <div className="logo-header mostion">
                <a href="/">
                  <img
                    src="./assets/images/Lcc-Logo.svg"
                    width="193"
                    height="89"
                    alt=""
                  />
                </a>
              </div>

              {/* <!-- nav toggle button --> */}
              <button
                data-target=".header-nav"
                data-toggle="collapse"
                type="button"
                className="navbar-toggle collapsed"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>

              {/* <!-- main nav --> */}
              <div className="header-nav navbar-collapse collapse">
                <ul className=" nav navbar-nav">
                  <li>
                    <a href={routes.public.home}>Home</a>
                  </li>
                  <li> 
                    <a href="#">
                      About Us<i className="fa fa-chevron-down"></i>
                    </a>
                    <ul className="sub-menu">
                      <li>
                        <a href={routes.public.about}>Our History</a>
                      </li>
                      <li>
                        <a href={routes.public.management}>Management</a>
                      </li>
                    </ul>
                  
                  </li>
                  <li>
                  <a href={routes.public.clubEvents}> News &#38; Events</a>
                  </li>
                  <li>
                    <a href="#">
                      sections<i className="fa fa-chevron-down"></i>
                    </a>
                    <ul className="sub-menu">
                      <li>
                        <a href={routes.public.snookerSection}>Snooker/billiard</a>
                      </li>
                      <li>
                        <a href={routes.public.swimmingSection}>swimming</a>
                      </li>
                      <li>
                        <a href={routes.public.tennisSection}>Tennis</a>
                      </li>
                      <li>
                        <a href={routes.public.dartSection}>Darts</a>
                      </li>
                      <li>
                        <a href={routes.public.badmintonSection}>badminton</a>
                      </li>
                      <li>
                        <a href={routes.public.tableTennis}>table tennis</a>
                      </li>
                      <li>
                        <a href={routes.public.squashSection}>squash</a>
                      </li>
                      <li>
                        <a href={routes.public.scrabbleSection}>scrabble</a>
                      </li>
                      <li>
                        <a href={routes.public.taekwondoSection}>Aerobics / Tackwando</a>
                      </li>
                      <li>
                        <a href={routes.public.otherGamesSection}>
                          Other games
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a href={routes.public.contact}>
                      Contact us
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      Login<i className="fa fa-chevron-down"></i>
                    </a>
                      <ul className="sub-menu">
                        <li>
                          <a href={routes.public.login}>
                             Members 
                          </a>
                        </li>
                        <li>
                          <a href="https://admin.lagoscountryclub.net/">
                              Admin 
                          </a>
                        </li>
                      </ul>
                  </li>
                  <li>
                    <a href={routes.public.hallBooking}>
                      Hall booking
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- main header END --> */}
      </header>
    </>
  );
}
