import React from "react";

export default function JoinSection({title, desc}) {
  return (
    <>
      <div
        className="section-full bg-primary content-inner-1 overlay-black-dark our-projects-galery"
        style={{ backgroundImage: "url(./assets/images/mamber.jpg)" }}
      >
        <div className="container">
          <div className="row text-white">
            <div className="col-md-12 text-center section-head">
              <h2 className="h2">{title}</h2>
              <div className="dlab-separator bg-white"></div>
              <p className="m-b0">
               {desc}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
