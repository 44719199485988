import React from "react";
import TableTennisData from "../pagesData/tableTennisData";
import { SectionHeader } from "../../../components";

class TableTennisGallery extends React.Component {
  constructor() {
    super();

    this.state = {
        gallerySection: TableTennisData,
      };
  } 

  render() {
    return (
      <>
         {/* <!-- Gallery section --> */}
         <div
            className="section-full text-center text-white bg-img-fix content-inner overlay-black-middle our-projects-galery"
            style={{
              backgroundImage: "url(./assets/images/background/bg1.jpg)",
            }}
          >
            <div className="container">
              {/* <!-- Section Header --> */}
              <SectionHeader titleSub={`Table Tennis Gallery`} />
              {/* <!-- ENd Section Header --> */}
              <div className="row">
                <ul className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery m-b0">
                  <div className="section-center">
                  {this.state.gallerySection.map(({ id, alt, img }) => (
                        <li
                          key={id}
                          className="card-container col-lg-4 col-md-4 col-sm-6 col-xs-6 "
                        >
                          <div className="dlab-box dlab-gallery-box m-b0">
                            <div className="dlab-media dlab-img-overlay1 dlab-img-effect rotate">
                              <a href="">
                                <img src={img} alt={alt} />
                              </a>
                              <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <a href={img} className="mfp-link">
                                    <i className="fa fa-picture-o icon-bx-xs"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      
                  ))}
                  </div>
                </ul>
              </div>
            </div>
          </div>

      </>
    );
  }
}

export default TableTennisGallery;
