import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { PageHeader } from "../../components";
// import { useMember } from "../../context/memberContext";
import secureLS from "../../encryption/secureLs";
import routes from "../../routes/routes";
import {useRef} from "react";
import ResetPassword from "./resetPassword";

export default function ChangePassword() {
    const history = useHistory()
    const email = useRef();
    const usertoken = useRef();
    const password = useRef();

    const tokenUrl = 'https:/message.lagoscountryclub.net/reset_password.php';

    const resetPassword = (e)=>{
        e.preventDefault();
        const resetData = {
            userEmail : email.current.value,
            userOtp : usertoken.current.value,
            userpassword: password.current.value
        }
        console.log(resetData)
        axios.post(tokenUrl,resetData).
        then(res=>{
            setMessage(res.data);
            setTimeout(() => {
                history.push(routes.public.login)
            }, 5000)
        })
    }

    const { register, handleSubmit, errors } = useForm();
    const mutation = useMutation(Data => axios.post(routes.api.updatePassword, {
        member_id: secureLS.get("member")[0].member_id,
        ...Data
    }))
    const [message, setMessage] = useState("")

    // console.log(secureLS.get("member")[0].id);
    useEffect(() => {
        console.log(secureLS.get("member"))
        return () => {
            secureLS.remove("member")
        }
    }, [])
    const onSubmit = async (data) => {
        await mutation.mutate(data, {
            onSuccess: async (data) => {
                await setMessage(["Successful"])

                setTimeout(() => {
                    history.push(routes.public.login)
                }, 3000)
                
              },
              onError: async (error) => {
                //   setMessage(["Member does not exist"])
                // console.log(error.response.data);
              },
            // onSettled: (data, error, variables, context) => {
            //     // I will fire second!
            //   },
        })
        console.log("input >>>",data);
        
    };
    return (
        <>
          <PageHeader title="Reset Password" page="Reset Password" />
            <div className="container" style={{ margin: "200px", marginTop: 20 }}>
                <div className="row">
                    <div className="col-md-7 col-md-offset-1 p-lr40 p-t30 p-b20 clearfix mack-an-appointment black border-1">
                        <div className="card card-body">
                            <h1 className="text-center mb-3">
                                <i className="fas fa-sign-in-alt"></i>
                                Reset password
                            </h1>
                            <p className="text-success">{message}</p>
                            <form onSubmit={resetPassword}>
                            <div className="form-group">
                                    <label htmlFor="email">Email Address </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Enter your email address"
                                        ref={email}
                                        required
                                    />
                                    
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">OTP </label>
                                    <input
                                        type="text "
                                        id="usertoken"
                                        name="usertoken"
                                        className="form-control"
                                        placeholder="Enter the the OTP sent to your "
                                        ref={usertoken}
                                        required
                                    />
                                    
                                    
                                </div>
                                <div className="form-group">
                                    <label htmlFor="cPassword"> New Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="Create New Password"
                                        ref={password}
                                        required
                                    />
                                    
                                </div>
                               
                                <div className="col-md-12 text-center">
                                    <button
                                        name="submit"
                                        type="submit"
                                        className="site-button"
                                        style={{ fontSize: 20, fontWeight: 500 }}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
