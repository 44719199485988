import PRESIDENT from "./PRESIDENT";
import VICEPRESIDENT from "./VICEPRESIDENT"
import SECRETARYGENERAL from "./SECRETARYGENERAL"
import HONTREASURER from "./HONTREASURER";
import HONCOUNCILMEMBER from "./HONCOUNCILMEMBER"
import HONSOCIALSECRETARY from "./HONSOCIALSECRETARY"
import LEGALADVISER  from "./LEGALADVISER"
import MEMBERSHIPSECRETARY from "./MEMBERSHIPSECRETARY"
import GENERALMANAGER from "./GENERALMANAGER"
import  SPORTSSECRETARY from "./SPORTSSECRETARY"
import AEROBICSTAKEWONDO from "./AEROBICSTAKEWONDO"
import CHAIRMANSCRABBLE from "./CHAIRMANSCRABBLE"
import CHAIRMANSQUASH from "./CHAIRMANSQUASH"
import CHAIRMANTENNIS from "./CHAIRMANTENNIS"
import CHAIRMANBADMINTON from "./CHAIRMANBADMINTON"
import CHAIREMANDART from "./CHAIREMANDART"
import CHAIRMANTABLE from "./CHAIRMANTABLE";
import OTHERGAME from "./OTHERGAME"
import CHAIRMANSWIMMING from "./CHAIRMANSWIMMING"
import CHAIRMANSNOOKER from "./CHAIRMANSNOOKER"


export const PRESIDENTS = PRESIDENT
export const VICEPRESIDENTS = VICEPRESIDENT
export const SECRETARYGENERALS = SECRETARYGENERAL
export const HONTREASURERS = HONTREASURER
export const HONCOUNCILMEMBERS = HONCOUNCILMEMBER
export const HONSOCIALSECRETARYS = HONSOCIALSECRETARY
export const LEGALADVISERS = LEGALADVISER
export const MEMBERSHIPSECRETARYS = MEMBERSHIPSECRETARY
export const GENERALMANAGERS = GENERALMANAGER
export const  SPORTSSECRETARYS =  SPORTSSECRETARY
export const AEROBICSTAKEWONDOS = AEROBICSTAKEWONDO
export const CHAIRMANSCRABBLES = CHAIRMANSCRABBLE
export const CHAIRMANSQUASHS = CHAIRMANSQUASH
export const CHAIRMANTENNISS = CHAIRMANTENNIS
export const CHAIRMANBADMINTONS = CHAIRMANBADMINTON
export const CHAIREMANDARTS = CHAIREMANDART
export const CHAIRMANTABLES = CHAIRMANTABLE
export const OTHERGAMES = OTHERGAME
export const CHAIRMANSWIMMINGS = CHAIRMANSWIMMING
export const CHAIRMANSNOOKERS = CHAIRMANSNOOKER