import React from "react";
import routes from "../../routes/routes";

export default function FooterSection() {
  return (
    <>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-6 footer-col-4">
              <div className="widget widget_services">
                <h4 className="m-b15 text-uppercase">QUICK LINKS</h4>
                <ul>
                  <li>
                    <a href="./assets/images/members_benefits.pdf">Benefits</a>
                  </li>
                  <li>
                    <a href="./assets/images/MEMBERSHIP CRITERIA AND APPLICATION PROCEDURE.pdf">Criteria</a>
                  </li>
                  <li>
                    <a href={routes.public.login}>Membership</a>
                  </li>
                  <li>
                    <a href={routes.public.management}>Management</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 footer-col-4">
              <div className="widget widget_services">
                <h4 className="m-b15 text-uppercase">AFFILIATIONS</h4>
                <ul>
                  <li>
                    <a href="https://web.facebook.com/lagoslawntennisclub" target="_blank">
                      Lagos Lawn Tennis Club, Lagos</a>
                  </li>
                  <li>
                    <a href="http://www.facebook.com/ApapaClub" target="_blank">
                      Apapa Club, Apapa
                    </a>
                  </li>
                  <li>
                    <a href="http://facebook.com/ITCSportingClub" target="_blank">
                      Ibadan Tennis Club, Ibadan
                    </a>
                  </li>
                  <li>
                    <a href="https://web.facebook.com/IbadanRecreationClub" target="_blank">
                      Ibadan Recreation Club, Ibadan</a>
                  </li>
                  <li>
                    <a href="https://web.facebook.com/Rotary-Club-of-Uyo-110318879048601" target="_blank">
                      Uyo Club, Uyo</a>
                  </li>
                  <li>
                    <a href="https://facebook.com/ABA-Sports-CLUB-1926-254802221528724/" target="_blank">
                      Aba Club, Aba</a>
                  </li>
                  <li>
                    <a href="/">Other Affiliates</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 footer-col-4">
              <div className="widget widget_services">
                <h4 className="m-b15 text-uppercase">Sections</h4>
                <ul>
                  <li>
                    <a href={routes.public.tennisSection}>Tennis</a>
                  </li>
                  <li>
                    <a href={routes.public.snookerSection}>Snooker</a>
                  </li>
                  <li>
                    <a href={routes.public.swimmingSection}>Swimming</a>
                  </li>
                  <li>
                    <a href={routes.public.taekwondoSection}>Taekwondo</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 footer-col-4">
              <div className="widget widget_getintuch">
                <h4 className="m-b15 text-uppercase">Contact Us</h4>
                <ul>
                  <li>
                    <i className="fa fa-map-marker"></i>
                    <strong>Address</strong>2 Joel Ogunnaike Street Off Mobolaji
                    Bank Anthony Way GRA Ikeja, Lagos
                  </li>
                  <li>
                    <i className="fa fa-phone"></i>
                    <strong>Phone</strong>0708 860 5062
                  </li>
                  <li>
                    <i className="fa fa-envelope"></i>
                    <strong>Email</strong>
                    <a href="mailto:info@lagoscountryclub.net">
                      info@lagoscountryclub.net
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 footer-col-4 ftr-newsletter">
              <div className="widget">
                <h4 className="m-b15 text-uppercase">Our Newsletter</h4>
                <form className="dez-subscribe-form">
                  <p>Enter your e-mail and subscribe to our newsletter.</p>
                  <div className="input-group m-b15">
                    <input
                      name="dzEmail"
                      required=""
                      className="form-control "
                      placeholder="Enter Your Email"
                      type="email"
                    />
                  </div>
                  <div className="input-group">
                    <button
                      name="submit"
                      type="submit"
                      value="Submit"
                      className="site-button btn-block"
                    >
                      SUBSCRIBE
                      <i className="fa fa-angle-right font-18 m-l10"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer bottom part --> */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-left">
              <span>
                Copyright © 2021 |
                <span style={{ color: "#CCA643" }}> Lagos Country Club </span>|
                All Rights Reserved.
              </span>
            </div>
            <div className="col-md-6 text-right ">
              <a
                href="http://www.facebook.com/lcc.ng"
                className="fa fa-facebook"
              ></a>

              <a
                href="http://twitter.com/lcc_ng"
                className="fa fa-twitter "
              ></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
