import React from "react";
import {
  AboutTeam,
  HeroFooter,
  PageHeader,
  SectionTeam,
} from "../../components";
import { GrandPatrons, LCCPatrons, Patrons, Trusteess, VicePatrons } from "./Patron";


export default function Management() {
  return (
    <div className="page-content">
      <PageHeader title="Management" page="management" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- contact --> */}
        <div className="content"></div>
        {/* <!-- contact area  END --> */}
        {/* <!-- Team member --> */}
        <div className="section-full bg-white content-inner">
          <div className="container">
            <div className="section-head text-center ">
              <h3 className="h3">MANAGEMENT COUNCIL MEMBERS</h3>
              <div className="dlab-separator bg-primary"></div>
              <p>
                The Club is governed (according to the Constitution Rules and
                Regulations of the Club), by a Management Council comprising a
                President and 9 Portfolio Officers, together with Chairmen of
                the different Sections..
              </p>
            </div>
            <div className="section-content ">
              <div className="row text-center">
                <AboutTeam />
              </div>
            </div>
          </div>
        </div>
        <div className="section-full bg-white content-inner">
          <div className="container">
            <div className="section-head text-center ">
              <h3 className="h3">SECTIONS COUNCIL MEMBERS</h3>
              <div className="dlab-separator bg-primary"></div>
            </div>
            <div className="section-content ">
              <div className="row text-center">
                <SectionTeam />
              </div>
            </div>
          </div>
        </div>
        <div className="section-full bg-white content-inner">
          <div className="container">
            <div className="section-head text-center ">
              <h3 className="h3">Grand-Patron</h3>
              <div className="dlab-separator bg-primary"></div>
            </div>
            <div className="section-content text-center">
              <div className="row text-center">
                 <GrandPatrons />
              </div>
            </div>
          </div>
        </div>
        <div className="section-full bg-white content-inner">
          <div className="container">
            <div className="section-head text-center ">
              <h3 className="h3">Patron</h3>
              <div className="dlab-separator bg-primary"></div>
            </div>
            <div className="section-content text-center">
              <div className="row text-center">
                 <Patrons />
              </div>
            </div>
          </div>
        </div>
        <div className="section-full bg-white content-inner">
          <div className="container">
            <div className="section-head text-center ">
              <h3 className="h3">VICE PATRONS</h3>
              <div className="dlab-separator bg-primary"></div>
            </div>
            <div className="section-content text-center">
              <div className="row text-center">
                 <VicePatrons />
              </div>
            </div>
          </div>
        </div>
        <div className="section-full bg-white content-inner">
          <div className="container">
            <div className="section-head text-center ">
              <h3 className="h3">BOARD OF TRUSTEES (BOT)</h3>
              <div className="dlab-separator bg-primary"></div>
            </div>
            <div className="section-content text-center">
              <div className="row text-center">
                 <Trusteess />
              </div>
            </div>
          </div>
        </div>
        <div className="section-full bg-white content-inner">
          <div className="container">
            <div className="section-head text-center ">
              <h3 className="h3">LCC. PAST PRESIDENTS</h3>
              <div className="dlab-separator bg-primary"></div>
            </div>
            <div className="section-content text-center">
              <div className="row text-center">
                 <LCCPatrons />
              </div>
            </div>
          </div>
        </div>
      </div>
      <HeroFooter />
    </div>
  );
}
