const scrabbleData = [
  {
    id: 1,
    alt: "gallery image",
    img: "/assets/images/section/Scrabble/sc1.jpg",
  },
  {
    id: 2,
    alt: "gallery image",
    img: "/assets/images/section/Scrabble/sc2.jpg",
  },
  {
    id: 3,
    alt: "gallery image",
    img: "/assets/images/section/Scrabble/sc3.jpeg",
  }
  ,
  {
    id: 4,
    alt: "gallery image",
    img: "/assets/images/section/Scrabble/sc4.jpeg",
  }
  ,
  {
    id: 5,
    alt: "gallery image",
    img: "/assets/images/section/Scrabble/sc5.jpeg",
  }
  ,
  {
    id: 6,
    alt: "gallery image",
    img: "/assets/images/section/Scrabble/sc6.jpeg",
  },
  {
    id: 7,
    alt: "gallery image",
    img: "/assets/images/section/Scrabble/sc7.jpeg",
  }
];
export default scrabbleData;
