// <!-- The core Firebase JS SDK is always required and must be listed first -->
// <script src="https://www.gstatic.com/firebasejs/8.2.9/firebase-app.js"></script>

// <!-- TODO: Add SDKs for Firebase products that you want to use
//      https://firebase.google.com/docs/web/setup#available-libraries -->
// <script src="https://www.gstatic.com/firebasejs/8.2.9/firebase-analytics.js"></script>

// <script>
//   // Your web app's Firebase configuration
//   // For Firebase JS SDK v7.20.0 and later, measurementId is optional
//   var firebaseConfig = {
//     apiKey: "AIzaSyDJwqzGA5Wz0lKN0_jR0KtdyxUCZi8jxNU",
//     authDomain: "lcc-otp.firebaseapp.com",
//     projectId: "lcc-otp",
//     storageBucket: "lcc-otp.appspot.com",
//     messagingSenderId: "191581705317",
//     appId: "1:191581705317:web:95a0711225db4494cba28c",
//     measurementId: "G-H3HMFKM9T9"
//   };
//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);
//   firebase.analytics();
// </script>

var config = {
    apiKey: "AIzaSyDJwqzGA5Wz0lKN0_jR0KtdyxUCZi8jxNU",
    authDomain: "lcc-otp.firebaseapp.com",
    projectId: "lcc-otp",
    storageBucket: "lcc-otp.appspot.com",
    messagingSenderId: "191581705317",
    appId: "1:191581705317:web:95a0711225db4494cba28c",
    measurementId: "G-H3HMFKM9T9"
}

export default config;