import * as React from 'react'
const MemberContext = React.createContext()
function countReducer(state, action) {
  switch (action.type) {
    case 'storeMember': {
      return {member: action.payload}
    }
    case 'decrement': {
      return {member: {}}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
function MemberProvider({children}) {
  const [state, dispatch] = React.useReducer(countReducer, {member: {}})
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatch}
  return <MemberContext.Provider value={value}>{children}</MemberContext.Provider>
}
function useMember() {
  const context = React.useContext(MemberContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a MemberProvider')
  }
  return context
}
export {MemberProvider, useMember, MemberContext}