import React from "react";
import style from "../../../pages/sections/style.module.css"

export default function TeamCard({img, name, title }) {
  return (
    <>
      {/* <!-- Team member --> */}
      <div className="section-full bg-white content-inner">
        <div className="container">
          <div className="section-head text-center ">
            <h3 className="h3">OFFICER IN CHARGE</h3>
            <div className="dlab-separator bg-primary"></div>
          </div>
          <div className="section-content ">
            <div className={`row text-center ${style.cardContainer}`}>
              <div className={`col-md-3 col-sm-6 `}>
                <div className="dlab-box m-b30 team-1">
                  <div className="dlab-media">
                    <a href="#">
                      <img
                        height="400px"
                        src={img}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="p-a10">
                    <h4 className="dlab-title text-uppercase">
                      <a href="#">{name}</a>
                    </h4>
                    <span className="dlab-member-position text-uppercase">
                     {title}
                    </span>
                    <br />
                    <ul className="dlab-social-icon">
                      <li className={`${style.cardContainerList}`}>
                        <a href="#" className="fa fa-facebook site-button"></a>
                      </li>
                      <li className={`${style.cardContainerList}`}>
                        <a href="#" className="fa fa-twitter site-button"></a>
                      </li>
                      <li className={`${style.cardContainerList}`}>
                        <a href="#" className="fa fa-linkedin site-button"></a>
                      </li>
                      <li className={`${style.cardContainerList}`}>
                        <a
                          href="#"
                          className="fa fa-google-plus site-button"
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Team member --> */}
    </>
  );
}
