import React from "react";
import ClubTeam from "../clubTeam"
import CARD_DATA from "./data"

class ClubDirectory extends React.Component {
  constructor() {
    super();

    this.state = {
        TeamSection: CARD_DATA,
      };
  }

  render() {
    return (
      <>
        {this.state.TeamSection.map(({ ...otherSectionProps }) => (
          <ClubTeam {...otherSectionProps} />
        ))}   
      </>
    );
  }
}

export default ClubDirectory;
