import React from 'react';
import { withRouter } from "react-router-dom";
import routes from "../../routes/routes";

const BlogList = ({id, title, image_path, category,description , history}) => {
    const handleClick = () => {
        history.push(`/events/${id}` ,{
            title,
            description,
            image_path
        })
    }
  return (
    <>
        <div className="blog-post blog-md clearfix date-style-4 event-post ">
           <div className="dlab-post-media dlab-img-effect rotate">
             <a 
             style={{cursor: 'pointer'}}
             onClick={handleClick}
             >
               <img
                    src={`${routes.imageLink + image_path}`}
                        alt="images"
                  />
             </a>
           </div>
           <div className="dlab-post-info">
             <div className="dlab-post-title ">
               <h2 className="post-title">
                 <a 
                  style={{cursor: 'pointer'}}
                  onClick={handleClick}>
                   {title}
                 </a>
               </h2>
             </div>
             <div className="dlab-post-meta ">
               <ul>
                 <li className="post-date">
                   <i className="fa fa-calendar"></i>
                   <span style={{fontSize: "20px", padding: "10px 20px"}}>{id}</span>
                 </li>
               </ul>
             </div>
             <div className="dlab-post-text">
               <h4>
              {`${description.substring(0, 400)}...`}
               </h4>
             </div>
             <div className="dlab-post-readmore">
               <a
                 style={{cursor: 'pointer'}}
                 onClick={handleClick}
                 title="READ MORE"
                 rel="bookmark"
                 className="site-button-link"
               >
                 READ MORE<i className="fa fa-angle-double-right"></i>
               </a>
             </div>
           </div>
         </div>

    </>
  )
};

export default withRouter(BlogList);