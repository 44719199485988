import React from 'react';
import { PageHeader } from "../../../components";

export default function HONSOCIALSECRETARY() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_SOCIAL_SECRETARY.JPEG"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">MR.OSEMOJE LATO</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                  Osemoje Lato was born to the family of Mr. Roland & Mrs. Beatrice Lato of Benin City, Edo State of Nigeria in July 26th 1977. He attended St. Theresa’s Primary School, Marine Beach, Apapa from 1981 – 1986 and St. Gregory’s College Obalende, Ikoyi Lagos from 1987 – 1992 He graduated from Ambrose Alli University, Ekpoma, with a B.Sc in Economics in 2001.
                  <br></br>
                  He started his working career with First October International in 2003 as an Operations manager before moving to Enownow as Client Service Manager. He also have a brief stint at Seed Media Limited as Head of New Media,His passion for marketing communications drove him to set up his media marketing communications agency in 2009 -  Chase Media Solutions a New media and marketing communications firm.
                  <br></br>
                    His specialty is in the online/digital marketing space. He further diversified into other forms of marketing communications, Branding, Fabrication and Corporate communications.
                    <br></br>
                  He is currently the MD/CEO of Chase Media Solutions Limited, Managing Partner, Sales & Business Director Visual Response Solutions Ltd.
                  His wealth of experience is quite impressive having been involved in the following achievements:
                  ·         The first to Launch Yahoo! Advert Campaign in Nigeria
                  ·         Won the best Exhibition stand for Eterna Plc at the OTL Conference in                 2018 & 2019
                  
                  <br></br>
                  His professional membership includes National Institute of Marketing of Nigeria. (NIMN)
                  Change Maker President of Rotary Club Anthony. Also a member of Lagos Country Cub
                  <br></br>
                  
                  PAST SERVICE
                  <br></br>
                  Member Club Media & Publicity Committee (2 Years)
                  Member Club New Year Committee (2 Years)
                  Entertainment Officer Snooker and Billiard Section (3 Years)
                  Entertainment Officer Squash Section (2 Years)
                  
                  <br></br>
                  He is Married with a Child.
 
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
