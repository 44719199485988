import React from "react";
import { PageHeader } from "../../components";
import routes from "../../routes/routes";
import BlogEvent from "./blog";
import BlogList from "./bloglist"

export default function ClubEvents() {
  return (
    <div className="page-content">
      <PageHeader title="Club Events" page="Events" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        <div className="section-full bg-white content-inner-1">
          <div className="container">
            {/* <!-- Blog half image --> */}
    
             <BlogEvent/>
           
            {/* <!-- Blog half image END --> */}
            {/* <!-- Pagination  --> */}
            <div className="pagination-bx clearfix ">
              <ul className="pagination">
                <li className="previous">
                  <a href="#">
                    <i className="fa fa-angle-double-left"></i>
                  </a>
                </li>
                <li className="active">
                  <a href="#">1</a>{" "}
                </li>
                <li>
                  <a href="#">2</a>
                </li>
                <li>
                  <a href="#">3</a>
                </li>
                <li className="next">
                  <a href="#">
                    <i className="fa fa-angle-double-right"></i>
                  </a>
                </li>
              </ul>
            </div>
            {/* <!-- Pagination END --> */}
          </div>
        </div>
      </div>
    </div>
  );
}
