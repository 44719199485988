import React from "react";

export default function clientLogo() {
  return (
    <>
      <div className="item">
        <div className="ow-client-logo">
          <div className="client-logo">
            <a href="/">
              <img src="https://lagoscountryclub.net/img/partners/06.jpg" alt="" />
            </a>
          </div>
        </div>
      </div>
      
    </>
  );
}
