import React from "react";
import { PageHeader } from "../../../components";

export default function SPORTSSECRETARY() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/babajideegbele.jpeg"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">MR. BABAJIDE EGBELE</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                  Mr. Babajide Egbele is a renowned swimming consultant, seasoned professional Sports Administrator, and ex-National Athlete - Nigerian Rower and Medalist. He participated in the FISA sponsored University of Pretoria, South Africa elite Athletes programme targeting the World Rowing Championship in New Zealand and the 2012 London Olympic Games. He is FINA (World Aquatics Federation) Level 2 Coach and FISA ( World Rowing Federation) Level 2 Coach. The Sports Director of SIDA. an NGO dedicated to persons with mental and physical challenges. His astounding doggedness and courage as Swimming Section Captain was instrumental to today’s newly improved section of the club. As an Ex-Nigerian National Athlete, he was immediately recommended for FISA certification owing to his passion and dedication to the sport and its corresponding development in Lagos State and in Nigeria at large. He has represented the club in various tournaments as participant and coach with resounding successes attached. He was Team Coach, Swimming to the Special Olympics held in UAE, 2019 with medals attached. His current role as Sports Secretary of the Club was largely due to his performances technically and administratively. He’s the Co-founder of Aquatic Spots, largely involved in mentoring and tutoring young athletes,  A sports visionary and leader; he was part of the team that revitalized the Lagos Regatta Festival that seeks to identify and empower talents from riverine areas.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
