import React from "react";
import './login.css';
import { SignInPage, PageHeader } from "../../components";

export default function LogIn() {
  return (
    <div className="page-content">
      <PageHeader title="MEMBERSHIP" page="membership" />


     <div className="col" id="logincss">
        <SignInPage />
     </div>
      
    </div>
  );
}
