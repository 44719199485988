const dashboard = "https://dashboard.lagoscountryclub.net"
const masterAdmin = "/master-admin"
const prod = !false

const server = "https://test.lagoscountryclub.net/api/";
const localhost = " http://127.0.0.1:8000/api/" ///192.168.43.95:8000

const localImageLink = "http://localhost:8000/events/"
const remoteImageLink = "https://admin.lagoscountryclub.net/assets/images/"


const routes = {
    master: masterAdmin,
    imageLink: (prod ? remoteImageLink : localImageLink),
    public: {
        home: "/",
        about: "/about",
        login: "/login",
        resetPassword: "/resetPassword",
        verifyOTP: "/verifyOTP",
        contact: "/contact",
        clubNews: "/club_news",
        clubEvents: "/club_events",
        eventDetails: "/events_details",
        newDetail_1: "/HANGING",
        EventsHanding: "/Memorial",
        protocolCovid: "/protocolCovid",
        tennisSection: "/tennis",
        snookerSection: "/snooker",
        dartSection: "/dart",
        swimmingSection: "/swimming",
        taekwondoSection: "/taekwondo",
        scrabbleSection: "/scrabble",
        badmintonSection: "/badminton",
        squashSection: "/squash",
        otherGamesSection: "/other_games",
        management: "/management",
        changePassword: "/changePassword",
        tableTennis: "/tableTennis",
        hallBooking:"/hallBooking",
        hallBookingRes:"/hallBookingRes"
    },
    api: {
        getEvent: (prod ? server : localhost) + 'events',
        getImage: prod ? remoteImageLink : localImageLink  ,
        contactUs: (prod ? server : localhost) + 'contact-us',
        login: (prod ? server : localhost) + 'auth/login',
        signUp: (prod ? server : localhost) + 'auth/register',
        contactUs: (prod ? server : localhost) + 'contactUs',
        hallBooking:(prod ? localhost : server) + "addBooking",
        resetPassword: (prod ? server : localhost) + 'auth/resetPassword',
        updatePassword: (prod ? server : localhost) + 'auth/updatePassword',
        
    },

    dashboard: {
        index: dashboard,
        dashboard: dashboard + "/dashboard",
    },
    masterAdmin: {
        index: masterAdmin,
        dashboard: masterAdmin + "/dashboard",
    }
}

export default routes
export const backendApi = (prod ? server : localhost)