import React from "react";

export default function SectionComponent({title, titleSub, desc}) {
  return (
    <>
      <div className="row">
        <div className="col-md-12 text-center section-head">
          <h2 className="h3 text-uppercase">
           {titleSub}
           <span className="text-primary">{title}</span>
          </h2>
          <div className="dlab-separator-outer ">
            <div className="dlab-separator bg-primary style-liner"></div>
          </div>
          <div className="clear"></div>
          <p className="m-b0">
            {desc}
          </p>
        </div>
      </div>
    </>
  );
}
