import React from "react";
import { PageHeader } from "../../../components";

export default function MEMBERSHIPSECRETARY() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_MEMBESHIP_SECRETARY.jpeg"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">Ola Azeez, PhD, MBA, FCIPM, FCILG, FICMi, HRPL, FITD, HRPL.</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                  Olasunkade Azeez has garnered high value net worth experiences as HR Leader, Business
Process Improvement expert, Certified Coach/Mentor, Learning, Training and Development
Leader, Senior Consultant, Member, Board of Directors, Board of Fellows, Governing Councils
and Board of Trustees (BOT) across manufacturing, oil and gas, consulting, construction, real
estate, aviation sectors, and Professional Institutes of the Nigerian economy in the last 3
decades. He started his working career with the Coca Cola Hellenic Bottling Company (CCHBC),
immediately after graduation in 1991. He served the Company meritoriously in various
capacities that prepared him for subsequent value-creating career path.<br></br> <br></br>

Ola Azeez is an alumnus of Obafemi Awolowo University, Ile-Ife, University of Lagos, and Lagos
State University, Ojo and he bagged Bachelor`s Degrees in Social Studies Education, Executive
Diploma HRM, Postgraduate Diploma (PR), Nigerian Institute of Journalism, and MBA Marketing
Management respectively. He holds Professional Doctor of Administration of Chartered
Institute of Business Managers and Administrators, Delaware, USA. He is also an alumnus of
many advanced management development programmes in Nigeria and abroad. He is an
internationally certified Trainer in HR, Talent Management, Coaching, Mentoring, Performance
Management Systems, Negotiation, Leadership, and Business Process Management System.
Trained in Nigeria, Austria, Greece, Czech Republic, Ireland, Dublin, and Romania etc. He is the
Managing Partner/CEO of REMOLAZ Management Consults, a division of Remolaz International
(Nig) Ltd. <br></br> <br></br>

He is currently the Group Head, Human Resources at Resort International Limited and
subsidiaries. The Holding Company comprises of fortune Companies in the Aviation,
Construction and Properties Development industries. He was a Senior Consultant and Team
Lead on HR Strategy and Change Management projects with Impact Business Consulting
Services, a Business Strategic Partner with IBM, USA. He joined CITA Petroleum Group
Executive Team (an IATA Strategic Partner and multiple awards winning enterprise) as
General Manager, Human Capital with over 2 decades of value adding business partnering
experience in HRM, Sales Operations, and General Administration, across various sectors. In
addition to Human Capital portfolio, he managed the Logistics subsidiary  JIL Transport as its
Chief Operating Officer in addition to an oversight responsibility for the Commercial and
Business Development functions of the Group and subsequently became the COO, CITA Organic
Ltd, with key responsibility for non-aviation fuelling business of the group. He is a change
management and strategic turnaround expert. <br></br> <br></br>

As a thoroughbred professional, he is a Fellow and a past Vice President of the Nigerian
Institute of Training and Development, (NITAD), a Fellow, and a one-time, Governing Council
Member of Chartered Institute of Nigeria, (CIPM) and also a Fellow/Member of Board of
Trustees (BOT) of Change Management Institute International (ICMi). He is a Fellow and Lagos
State Coordinator of Nigeria Chapter of Chartered Institute of Leadership and Governance, USA.
He is also a governing Council member of Grand Plus College of Education, Ilorin Kwara State,
Nigeria and OML Accra, Ghana Republic. <br></br> <br></br>
He is a columnist, writer and an author of two books and co-authored Learning Management
Systems and Facilitation (A cookbook for 21st century trainers) published in April 2022.
<br></br> <br></br>
Ola belongs to many other recreational, social and philanthropic associations. He is a member
of Association of International Lions Clubs, a onetime President, Ilupeju Lions Club and District
Officer under 404B2, Nigeria. A member of Ibadan Recreation Club, Omo Ajorosun Club, Ibadan
and member, Board of Trustees of Ibadan Borokinni Worldwide Forum (IBWF) among others.
He is the immediate past Vice Chairman of Badminton Section. He has served the Club in
various Committees of Council of the Club including Membership Screening, Staff and
Secretariat Staff Matters, 70th Planning Committee among others.
<br></br> <br></br>
Ola is happily married with children.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
