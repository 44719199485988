import React from "react";
import {Link} from "react-router-dom"

const SectionTeam = ({ Items }) => (
  <>
    {Items
    .slice(10, 50)
    .map(({ id, title, imageUrl, desc, name , link }) => (
      <div key={id} className="col-md-3 col-sm-6">
        <div className="dlab-box m-b30 team-1">
          <div className="dlab-media" style={{"display": "flex", "justifyContent": "center"}}>
            <img src={imageUrl} alt=""  style={{height: "270px",objectFit: "contain"}}/>
          </div>
          <div className="p-a10">
            <p className="dlab-title text-uppercase">{title}</p>
            <h4 className="dlab-title text-uppercase">{name}</h4>
            <span className="dlab-member-position">{`${desc.substring(0, 100)}...`}</span>
            <ul className="dlab-social-icon">
              <li>
                <a href="#" className="fa fa-facebook site-button"></a>
              </li>
              <li>
                <a href="#" className="fa fa-twitter site-button"></a>
              </li>
              <li>
                 <Link to={link} className="fa" style={{backgroundColor: "#CCA643", width: "80px", padding: "0px 5px"}}>View More</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    ))}
  </>
);

export default SectionTeam;
