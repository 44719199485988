const snookerData = [
  {
    id: 1,
    alt: "gallery image",
    img: "/assets/images/section/Snooker/s1.jpg",
  },
  {
    id: 2,
    alt: "gallery image",
    img: "/assets/images/section/Snooker/s2.jpg",
  },
  {
    id: 3,
    alt: "gallery image",
    img: "/assets/images/section/Snooker/s3.jpg",
  },
  {
    id: 4,
    alt: "gallery image",
    img: "/assets/images/section/Snooker/s4.jpg",
  },
  {
    id: 5,
    alt: "gallery image",
    img: "/assets/images/section/Snooker/sn5.jpeg",
  },
  {
    id: 6,
    alt: "gallery image",
    img: "/assets/images/section/Snooker/sn6.jpeg",
  },
  {
    id: 7,
    alt: "gallery image",
    img: "/assets/images/section/Snooker/sn7.jpeg",
  },
  {
    id: 8,
    alt: "gallery image",
    img: "/assets/images/section/Snooker/sn8.jpeg",
  },
];

export default snookerData;
