import React from "react";

const LCCPatron = () => (
  <>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/LCC_PR.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
              R. SIMPSON, ESQ
          </h4>
          <p>1950 – 1951</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/LCC_PR.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
             R. FULLER, OVINN, ESQ
          </h4>
          <p>1951 – 1952</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/LCC_PR.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
             O. WALSH, ESQ.
          </h4>
          <p>1952 – 1953</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/LCC_PR.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
              E. EVANS, ESQ.
          </h4>
          <p>1954 – 1957</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/LCC_PR.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
          R. STEWARD, ESQ.
          </h4>
          <p>1957 – 1959</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/M_P_WARD.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
            M. P. WARD, ESQ.
          </h4>
          <p>1959 – 1975</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/ADEMILUYI.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
          DR. PRINCE ADEMILUYI
          </h4>
          <p>1975 – 1976</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/KEATH_FISHER.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
          DR. KEATH FISHER
          </h4>
          <p>1976 – 1977</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/NADIN_ATTIK.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
            NADIM ATTIK, ESQ
          </h4>
          <p>1977 – 1980</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/ALH_JIMOH.jpg.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
           ALH. (CHIEF) S. A. JIMOH
          </h4>
          <p>1980 – 1983</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/ADEKUNLE_OSOMO.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
          CHIEF ADEKUNLE OSOMO
          </h4>
          <p>1983 – 1986</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/ONIGBANJO.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
          ALHAJI A. K. ONIGBANJO
          </h4>
          <p>1986 – 1988</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/ADEKUNLE_ADESHINE.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
          CHIEF ADEKUNLE ADESHINE	
          </h4>
          <p>1988 – 1991</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/KOLAWOLE_OYEFESO_1.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
            AARE (DR.) KOLAWOLE OYEFESO
          </h4>
          <p>1991 – 1994</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/BUKOLA_FAGBEMI.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
          CHIEF BUKOLA FAGBEMI
          </h4>
          <p>1994 – 1996</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/OLAYINKA_OGUNMEKAN.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
          CHIEF OLAYINKA OGUNMEKAN (AG)
          </h4>
          <p>1996 – 1997</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/OLUWAMUYIWA WILLIAMS.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="">
          <h4 className="dlab-title text-uppercase">
          CHIEF A. OLUMUYIWA WILLIAMS
          </h4>
          <p>1997 – 2000</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/Manny_Igbenogba.jpeg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
            CHIEF MANNY O. IGBENOBA	
          </h4>
          <p>2000 – 2002</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/OLAYINKA_OGUNMEKAN.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="">
          <h4 className="dlab-title text-uppercase">
          CHIEF OLAYINKA OGUNMEKAN
          </h4>
          <p>2002 – 2005</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/Femi_Olanipekun.jpeg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
          Chief OLufemi Olanipekun
          </h4>
          <p>2005 – 2008</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/TUNDE_OKUNUGA.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
           MR. BABTUNDE OKUNUGA
          </h4>
          <p>2008 – 2011</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/WALE_OSHOMO.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
            ADEWALE OSOMO ESQ
          </h4>
          <p>2011 – 2014</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/KAYODE_MORADEYO.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
          MR. KAYODE M. MORADEYO
          </h4>
          <p>2014 – 2017</p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/TAJUDEEN_AKANDE.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
            MR. TAJUDEEN ADEGBOYEGA AKANDE
          </h4>
          <p>2017 – 2020</p>
        </div>
      </div>
    </div>
 
 
  </>
);

export default LCCPatron;
