import React from "react";
import {  PageHeader, TeamMember } from "../../components";
import SwimmingGallery from "./directory/swimming";
import style from "./style.module.css";

export default function Swimming() {
  return (
    <div className="page-content">
      <PageHeader title="Swimming "  page="swimming"/>
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- contact --> */}
        <div className="content">
          <div class="section-full bg-gray content-inner ">
            <div class="container">
              <div class="row">
                <div class="col-md-5 col-sm-12 hidden-sm">
                  <img src="/assets/images/section/Swimming/sw1.jpg" alt="" class="m-b30" />
                </div>
                <div class="col-md-7 col-sm-12">
                  <h3 class="h2">WELCOME TO SWIMMING</h3>
                  <div class="dlab-separator bg-primary"></div>
                  <h4 class="font-weight-3 00">
                  Welcome to Swimming Section at the Lagos Country Club Ikeja. Swimming is well acclaimed worldwide as the best form of physical exercise of all parts of the body. By regularly getting involved in swimming, you not only exercise but ensure a healthy living as one the many benefits of swimming.
                  </h4>
                  <p>
                  Swimming Section apart from being the most populated of the ten Sections of this Club, has special consideration for our little ones (Children). An ultra modern baby-pool was constructed not too long ago for our children. In terms of ambience, oour Swimming Section is presently the best in the Club. This is the Section all members and guests would want to be part of. There is what is called water therapy often recommended by Doctors. Swimming balances you psychologically.
                  </p>
                  <p>
                  The exercise enables the mind function in a more balanced and relaxed stage. It rejuvenates the body, the Swimming Section is still developing with many projects penciled down and available for sponsorship hence we implore those of you in places of authority and who can make things happen in terms of sponsorship to come to our aid. Lest i forget, to non-swimmers who desire to learn swimming, we have four (4) well-trained and certificated coaches who would put you through within 10 lessons. You are welcome once again. For any enquiries, please contact members of the Management Committee. Thank you.
                  </p>
                  
                  <p>
                    Advantages of Swimming:
                    <ul className={`${style.marginVertical}`}>
                      <li>
                      Swimming relives stress.
                      </li>
                      <li>Swimming pool adds to aesthetics of its environment.</li>
                      <li>You do not sweat while exercising unlike other games.</li>
                      <li>It is a sport that can be done alone</li>
                      <li>You need not wait for any partner, except during competitions.</li>
                      <li>It is the only sport where you can have all the family members in the arena (pool).</li>
                      <li>It is therefore highly recommended.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}

        <SwimmingGallery />
         {/* <!-- Team member --> */}
         <TeamMember 
          name="MR. AYOKO OLUSOJI HENRY"
          title="LCC CHAIRMAN SWIMMING"
          img="./assets/images/members/LCC2.JPG" />
        {/* <!-- Team member --> */}
      </div>
    </div>
  );
}
