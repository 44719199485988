import React from "react";
import { PageHeader, TeamMember } from "../../components";
import SnookerGallery from "./directory/snooker";
import style from "./style.module.css";
export default function Snooker() {
  return (
    <div className="page-content">
      <PageHeader title="Snooker " page="snooker" />
      {/* <!-- Content -> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row --> */}

        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          {/* <!-- Our Awesome Services --> */}
          <div className="section-full bg-gray content-inner ">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center section-head mamber-dec">
                  <h3 className="h2">WELCOME TO SNOOKER</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <div className="clear"></div>
                  <h5 class="font-weight-3 m-b30">
                  The oldest section in the club. Recent renovation and restoration work in the section has made it the cynosure of all eyes with three (3) full size tables and an eye-pleasing interior décor, the section is easily “the place to be”. The section is socially vibrant, with sponsored events holding frequently. Competitions are on-going exercises.
                  </h5>
                  {/* <a href="/#" className="site-button">Learn More</a> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- About Company END --> */}
          {/* <!-- Gallery Section --> */}
          <SnookerGallery />
          {/* <!-- Team member --> */}
          <TeamMember
            name="MR. KAYODE OLUWOLE"
            title="Chairman Snooker Sections."
            img="./assets/images/members/LCC3.jpeg"
          />
          {/* <!-- Team member --> */}
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
