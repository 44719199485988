import React, { Component } from 'react';
import firebaseConfig from '../../fireBase/firebaseConfig';
import * as firebaseui from "firebaseui";
import firebase from "firebase";
import { PageHeader } from '../../components';
import routes from '../../routes/routes';
import { MemberContext } from '../../context/memberContext';
import secureLS from '../../encryption/secureLs';

class VerifyOTP extends Component {

    componentDidMount() {
        // console.log(">>>>>",this.props.location.state);
        const fbase = firebase.initializeApp(firebaseConfig);
        const uiConfig = {
            signInSuccessUrl: routes.public.changePassword, //This URL is used to return to that page when we got success response for phone authentication.
            signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
            tosUrl: routes.public.changePassword,
            callbacks: {
                signInSuccessWithAuthResult: async (authResult) => {
                    await secureLS.set("member", this.props.location.state)
                    // console.log(authResult)
                    return false
                },
            },

        };
        var ui = new firebaseui.auth.AuthUI(firebase.auth());
        ui.start("#firebaseui-auth-container", uiConfig);
    }
    render() {
        // console.log(this.props.location.state);
        return (
            <>
                <MemberContext.Consumer>
                    {context => {
                        if (context === undefined) {
                            throw new Error('CountConsumer must be used within a CountProvider')
                        }
                        return (
                            <>
                                <PageHeader title="Verify OTP" page="Verify OTP" />
                                <h1 style={{ padding: "2rem" }}>Please Verify it's really you.</h1>
                                <div id="firebaseui-auth-container"></div>
                            </>
                        )

                    }}
                </MemberContext.Consumer>

            </>
        )
    }
}

export default VerifyOTP;