import React from "react";
import { withRouter } from "react-router-dom";
import routes from "../../routes/routes";


const BlogCard = ({ id, title, image_path, category,description, history }) => {
  const handleClick = () => {
    history.push(`/Blogs/${id}` ,{
        title,
        description,
        image_path
    })
}
  return (
    <>
      <div className="item">
        <div className=" ">
          
          <div className="dlab-post-media dlab-img-effect  rotate">
            <img
              src={`${routes.imageLink + image_path}`}
              alt="image"
              style={{height: "300px", objectFit: "cover"}}
            />
          </div>
          <div className="ow-post-info ">
            <div className="ow-post-title">
              <h4 className="post-title">
              {`${title.substring(0, 300)}...`}
              </h4>
            </div>
            <div className="ow-post-meta">
              <ul>
                <li className="post-author">
                  <i className="fa fa-user"></i>{category}
                </li>
              </ul>
            </div>
            <div className="ow-post-text">
              <p>
              {`${description.substring(0, 300)}...`}
              </p>
            </div>
            <div className="ow-post-readmore ">
              <a
                 style={{cursor: 'pointer'}}
                 onClick={handleClick}
                 title="READ MORE"
                 rel="bookmark"
                 className="site-button-link"
               >
                 READ MORE<i className="fa fa-angle-double-right"></i>
               </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(BlogCard);