import React from 'react';
import TestimonialSecton from "../../sectionItem/TestimonialSecton"

const Testimonial = ({ Items }) => (
  <>
      {Items.map(({ id, ...otherItemProps }) => (
          <TestimonialSecton key={id} {...otherItemProps} />
        ))}
  </>
);

export default Testimonial;