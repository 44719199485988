import React from "react";
import { withRouter } from "react-router-dom";
import { PageHeader } from "../../components";
import routes from "../../routes/routes";

const EventsD = ({location:{state:{title, description,image_path}}}) => {
  return (
    <div classNameName="page-content">
      <PageHeader
        title={title}
        page="Event details"
      />
      {/* <!-- Content --> */}
      <div classNameName="page-content">
        <div className="section-full bg-white content-inner">
          <div className="container woo-entry">
            <div className="row">
              <div className="col-md-4">
                <div className="clearfix">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d126848.81976376878!2d3.3372391044290053!3d6.518440971475161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d6.4493912!2d3.4498355999999997!4m5!1s0x103b92159b76a9ab%3A0xaf8f3720edbd42bf!2slagos%20country%20club!3m2!1d6.5876472999999995!2d3.3564287!5e0!3m2!1sen!2sng!4v1613998267098!5m2!1sen!2sng"
                    style={{ border: 0, width: "100%", height: 400 }}
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                  <div className="dlab-divider bg-gray-dark">
                    <i className="icon-dot c-square"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="dlab-media m-b30">
                     <img
                        src={`${routes.imageLink + image_path}`}
                        alt="images"
                        width="200"
                        height="160"
                      />
                </div>

                <div className="dlab-tabs border bg-tabs product-description">
                  <div className="tab-content">
                    <div className="tab-pane active">
                      <h2 className="m-t0">
                         {title}
                      </h2>
                      <h4 className="m-b10">
                      {description}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EventsD);