import React from 'react';
import { PageHeader } from "../../../components";

export default function HONCOUNCILMEMBER() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_COUNCI_MEMBER.jpeg"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">Arc. Ashiru Tokunbo.</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                  Arc Adetokunbo Ashiru. is the MD/CEO ASHTKB VENTURES LTD He holds a B.Tech. Architecture from the Rivers State University of Science and Technology, Port Harcourt, Rivers State and Master’s Degree in Architecture at the same university. He also holds Diploma in Computing at AutoCad Centre Rivers State as well as America Project Management British College UK (BSPM UK), Executive Masters Project Management-British College UK (BSPM College) and Advance Diploma Project Management British College UK (BSPM UK).  Before the inception of his company, he has served as Project Manager/Architect with UBAPROPERTIES now Afriland Properties Plc and also rose through the ranks at United Bank of Africa Plc to the post of Project Manager/Architect UBA Properties.<br></br><br></br>
Ashiru also enjoys sports and social activities, he is a member Proud Member of Lagos Country Club and have served in various capacity particularly at Aerobics Taekwondo section and other sub-committees of the club. He has a 1st Dan Black Belt on KUKKIWON World Taekwondo Tournament in Korea.      
Architect Ashiru is married to Mrs Ekene Ashiru and are blessed with children.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
