import {
  HeroFooter,
  SectionHeader,
  JoinClub,
  TestimonialSilder,
  BlogSection,
  Gallery,
  ClientSilder,
  HomeTeam,
} from "../../components";
import VideoHeader from "../../components/header/videoHeader";
import routes from "../../routes/routes";

function HomePage() {
  return (
    <div id="bg">
      <div className="page-wraper ">
        {/* <!-- Content --> */}
        <div className="page-content">
          {/* <!-- Slider --> */}
          <VideoHeader />
          <HeroFooter />
          {/* <!-- Slider END --> */}

          {/* <!-- About Company --> */}
          <div className="section-full  bg-white content-inner about3">
            <div className="container">
              <div className="section-content">
                <div className="row">
                  <div className="col-md-12 text-center section-head">
                    <h2 className="h3 text-uppercase">
                      <span className="text-primary">WHO WE ARE</span>
                    </h2>
                    <div className="dlab-separator-outer ">
                      <div className="dlab-separator bg-primary style-liner"></div>
                    </div>
                    <div className="clear"></div>
                    <p className="m-b0">
                      Founded on 3rd August 1949 and incorporated under the Land
                      Perpetual Succession Ordinance, CAP 107 on 30th October
                      1956. The original site of the Club was the hanger of the
                      Royal Air Force by the West African Airways Corporation
                      (WAAC) owned by Anglophone West African Countries of
                      Nigeria, Ghana, Sierra Leone and the Gambia. Upon
                      independence of Nigeria in October 1960 WAAC was changed
                      to Nigeria Airways.
                    </p>
                    <div className="p-b10 p-a10" style={{ textAlign: "center" }}>
                      <a
                        href={routes.public.about}
                        className="site-button"
                        style={{
                          backgroundColor: "#CCA643",
                          width: "80px",
                          height: "40px",
                          padding: "10px 5px",
                        }}
                      >
                        View more
                      </a>
                    </div>
                  </div>
                </div>

                {/* <!-- ENd Section Header --> */}
                <div className="row">
                  <HomeTeam />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- About Company END --> */}

          {/* <!--Join Club section --> */}
          <JoinClub
            title="CONSTITUTION"
            desc=" The Club is governed by 'The Constitution 2013'. The first
            Constitution was revised in 1954. Subsequent Constitutions were
            also made in 1984 and further revised in 1997 and 2008
            respectively. The Laws governing the Club are to be found in the
            booklet, The Constitution 2013, as amended."
          />
          {/* <!-- End of Join Club section --> */}

          {/* <!-- Our Projects  --> */}
          <Gallery />
          {/* <!-- Our Projects END --> */}

          {/* <!-- blog post Carousel with no margin --> */}
          <div className="section-full bg-white content-inner-1">
            <div className="container">
              {/* <!-- Section Header --> */}
              <SectionHeader
                titleSub="LATEST EVENTS"
                desc="Events showcased by the club all through the year."
              />
              {/* <!-- ENd Section Header --> */}

              <div className="section-content">
                {/* <div className="blog-carousel mfp-gallery gallery owl-btn-center-lr"> */}
                  <BlogSection />
                {/* </div> */}
              </div>
            </div>
          </div>
          {/* <!-- blog post Carousel with no margin END --> */}

          {/* <!-- Testimonials blog --> */}
          <div
            className="section-full overlay-black-middle bg-img-fix content-inner-1"
            style={{
              backgroundImage: "url(./assets/images/background/bg2.jpg)",
            }}
          >
            <div className="container">
              {/* <!-- Section Header --> */}
              <div className="text-white">
                <SectionHeader titleSub="Members Testimonial" />
              </div>
              {/* <!-- ENd Section Header --> */}
              <div className="section-content">
                <div className="testimonial-two">
                  <TestimonialSilder />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Testimonials blog END --> */}

          {/* <!-- Client logo --> */}
          <div className="section-full dlab-we-find bg-img-fix p-t50 p-b50 ">
            <div className="container">
              <div className="section-content">
                <div className="client-logo-carousel mfp-gallery gallery owl-btn-center-lr">
                  <ClientSilder />
                  <ClientSilder />
                  <ClientSilder />
                  <ClientSilder />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Client logo END --> */}
        </div>
        {/* <!-- Content END--> */}

        {/* <!-- scroll top button --> */}
        <button className="scroltop fa fa-arrow-up"></button>
      </div>
    </div>
  );
}

export default HomePage;
