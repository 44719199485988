import React from "react";
import { PageHeader } from "../../../components";

export default function CHAIRMANSCRABBLE() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_CHAIRMAN_SCRABBLE.jpeg"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">Thunde Thani</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                  Thunde Thani was born to the Thani-Oladunjoye family of Epe, Lagos State, on 10th August,
1960.
Thani had his Primary Education at St Michael Anglican Primary School (CMS) Epe, his
Secondary Education at Ansar-ud-deen High School, Surulere, Lagos. He attended the
prestigious University of Lagos where he obtained his BSC (Hons) Mass Communication with
2
nd Class Upper. He also holds an MSC in mass communication from the same university.
An astute communicator, marketing and advertising expert. He is also a vivacious entrepreneur.
He is a fellow of Advertising Practitioners Council (APCON), severally serve on its board and
served as vice Chairman of the engine room of the body, the Advertising Standard Panel (ASP).
with over 3 decades in marketing communications practice, Ivorian Thani held many Executive
positions in the Association of Advertising Agencies of Nigeria (AAAN) and has handled multisectoral businesses/accounts including FMCG, Banking and Finance, Oil and Gas,
Government/Politics, Pharmaceuticals and multinationals. He sits as the managing Director/CEO
of the multi-award winning explicit group, a conglomerate of marketing communications
companies.
Thani a Philanthropist, recently facilitated a non-profit care Centre – Thani Oladunjoye Old
People Care Centre, for senior citizens in his community, Epe, Lagos State. He has won many
awards, both for his philanthropic gesture, social and political acumen including the prestigious
University of Lagos, Alumni Association Golden Jubilee Distinguishes Alumni Award and
Unilag Mass Communication Alumni Association UMCAA’s outstanding Alumni Entrepreneur
in Mass Communication Award – both award he cherishes.
It is not all work and no play. Thani is a Table Tennis buff and an ardent Scrabble player. He is
currently the Chairman of Lagos Country Club Scrabble Section. He is also a prominent member
of Great Summit Club.
Tunde Thani is happily Married to Christiana Omobolanle Thani and blessed with children.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
