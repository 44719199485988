import ClubDirectory from "./card/directory/master/Home_Team";
import TeamDirectory from "./card/directory/master/About_Team";
import SectionTeamDirectory from "./card/directory/master/Section_Team"
import BlogDirectory from "./card/directory/master/Blog_Section";
import TestimonyDirectory from "./card/directory/master/testimony_section";
import NavbarComponent from "./navbar/NavbarComponent";
import HeaderComponent from "./header/HeaderComponent";
import HeaderFooter  from "./header/HeaderFooter";
import pageHeader from "./header/pageHeader";
import SectionComponent from "./sectionItem/SectionComponent";
import JoinSection from "./sectionItem/JoinSection";
import GalleryApp from "./gallery/galleryApp";
import clientLogo from "./card/clientLogo";
import FooterSection from "./footer/FooterSection";
import SignIn from "./forms/signIn/SignIn";
import TeamCard from "./card/Team-member/teamCard";
 


export const HomeTeam = ClubDirectory
export const AboutTeam = TeamDirectory
export  const SectionTeam = SectionTeamDirectory
export const BlogSection = BlogDirectory
export const TestimonialSilder = TestimonyDirectory
export const SignInPage = SignIn
export const NavBar  =  NavbarComponent
export const HeaderHero = HeaderComponent
export const HeroFooter = HeaderFooter
export const PageHeader = pageHeader
export const SectionHeader = SectionComponent
export const JoinClub = JoinSection
export const Gallery = GalleryApp
export const ClientSilder = clientLogo
export const TeamMember = TeamCard
export const Footer = FooterSection;
