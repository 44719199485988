import React from "react";

const VicePatron = () => (
  <>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box m-b30 team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/Ooni.jpg"
            alt="Buhari"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <p className="dlab-title text-uppercase">His Royal Majesty</p>
          <h4 className="dlab-title text-uppercase">
            OBA ADEYEYE ENITAN OGUNWUSI
          </h4>
          <p className="dlab-member-position">
            OJAJA II, Ooni of Ife, Ile-Ife, Osun State.
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box m-b30 team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/Alake-Oba-Gbadebo.jpeg"
            alt="Buhari"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <p className="dlab-title text-uppercase">His Royal Majesty</p>
          <h4 className="dlab-title text-uppercase">
            OBA MICHAEL ADEDOTUN AREMU GBADEBO
          </h4>
          <p className="dlab-member-position">
            Okukenu IV, Alake of Egbaland, Ake’s Palace, Abeokuta – Ogun State.
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box m-b30 team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/akiolu.jpg"
            alt="Buhari"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <p className="dlab-title text-uppercase">His Royal Highness</p>
          <h4 className="dlab-title text-uppercase">OBA RILWAN AKIOLU II</h4>
          <p className="dlab-member-position">
            Oba of Lagos, Iga Iduganran, Isale-Eko, Lagos.
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box m-b30 team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/Obi-of-Onitsha.jpg"
            alt="Buhari"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <p className="dlab-title text-uppercase">His Royal Highness</p>
          <h4 className="dlab-title text-uppercase">
            IGWE NNAEMEKA ALFRED UGOCHUKWU ACHEBE
          </h4>
          <p className="dlab-member-position">
            The Obi of Onitsha, IGWE NNAEMEKA ALFRED UGOCHUKWU ACHEBE, CFR, mni.
            Obi’s Palace, Onitsha.
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box m-b30 team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/sanusi.jpg"
            alt="Buhari"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <p className="dlab-title text-uppercase">His Eminence</p>
          <h4 className="dlab-title text-uppercase">
            ALHAJI MUHAMMADU SANUSI II
          </h4>
          <p className="dlab-member-position">
            The Emir of Kano, The Emir’s Palace, Kano.
          </p>
        </div>
      </div>
    </div>
  </>
);

export default VicePatron;
