import React from "react";
import routes from "../../routes/routes";

export default function HeaderFooter() {
  return (
    <>
      <div
        className="section-full bg-primary p-tb30 get-a-quate"
        style={{ backgroundColor: "#fb7035 !important" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <span className="font-24 ">
                Recent protocol on COVID 19 as it affects the club activities
              </span>
            </div>
            <div className="col-md-4">
              <a
                className="site-button white pull-right radius-xl"
                href={routes.public.protocolCovid}
                style={{ backgroundColor: "white !important" }}
              >
                Read more
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
