import React from "react";
import {  HeroFooter, PageHeader, TeamMember } from "../../components";
import BadmintonGallery from "./directory/badminton";

export default function Badminton() {
  return (
    <div className="page-content">
      <PageHeader title="Badminton" page="Badminton" />
      {/* <!-- Content -- */}
      <div className="page-content">
        {/* <!-- contact --> */}
        <div className="content">
          <div class="section-fullbg-gray content-inner ">
            <div class="container">
              <div class="row">
                <div class="col-md-5 col-sm-12 hidden-sm">
                  <img src="/assets/images/gallery/b2.jpg" alt="" class="m-b30" />
                </div>
                <div class="col-md-7 col-sm-12">
                  <h3 class="h2">WELCOME TO BADMINTON</h3>
                  <div class="dlab-separator bg-primary"></div>
                  <h4 class="font-weight-3 00">
                    Welcome to Badminton Section at the Lagos Country Club
                    Ikeja. The Badminton Section has two standard playing courts
                    and a gallery that accommodates about a hundred people.
                  </h4>
                  <p>
                    Badminton is a Racquet sport played by either two opposing
                    players (singles) or two opposing pairs (doubles) who take
                    positions on opposite halves of a rectangular court that is
                    divided by a net. Players score points by striking a
                    shuttlecock with their Racquet so that it passes over the
                    net and lands in their opponents half of the court. A rally
                    ends once the Shuttlecock has hit the ground, and each side
                    may only strike the Shuttlecock once before it passes the
                    over the net.
                  </p>
                  <p>
                    The Shuttlecock (or Shuttle) is a feathered projectile whose
                    unique Aerodynamic properties cause it to fly differently
                    from the balls used in most Racquet sports; in particular,
                    the feathers create much higher drag, causing the
                    Shuttlecock to decelerate more rapidly than a ball.
                    Shuttlecocks have a much higher top speed, when compared to
                    other racquet sports. Because shuttlecock flight is affected
                    by wind, competitive badminton is best played indoors.
                    Badminton is also played outdoors as a casual recreational
                    activity, often as a garden or beach game.
                  </p>
                  <p>
                    Since 1992, badminton has an Olympic sport with five events:
                    men's and women's singles, men's and women's doubles, and
                    mixed doubles, in which each pair is a man and a woman. At
                    high levels of play, the sport demands excellent fitness:
                    players require aerobic stamina, agility, strength, speed
                    and precision. It is also a technical sport, requiring good
                    motor coordination and the development of sophisticated
                    racquet movements.
                  </p>
                  <p>
                    Badminton has been played since ancient times; an early form
                    of the sport was played in ancient Greece. In Japan, the
                    related game Harfetsuki was played as e"aflyas the 16th
                    century. In the west, badminton came from a game called
                    battledore and shuttlecock, in which two or more players
                    keep a feathered shuttlecock in the air with small racquets.
                    The International Badminton Federation (IBF) (now know as
                    Badminton World Federation) was established in 1934 with
                    Canada, Denmark, England, France, the Netherlands, Ireland,
                    New Zealand, Scotland and Wales as its founding members.
                    India joined as an affiliate in 1936. the BWF now governs
                    international badminton and develops the sport globally.
                    While set-out in England, International badminton has
                    traditionally been dominated by Denmark from Europe.
                    Indonesia, South Korea and Malaysia are among the nations
                    that have consistently produced world-class players in the
                    past few decades and dominated competitions on the
                    international level, with China being the most dominant in
                    recent years.
                  </p>
                  <p>
                    To win in badminton, players need to employ a wide variety
                    of strokes in the right situations. These range from
                    powerful jumping smashes to delicate tumbling net returns.
                    Often rallies finish with a smash, but setting up the smash
                    requires subtler strokes. For example, a netshot can force
                    the opponent to lift the shuttlecock, which gives an
                    opportunity to smash. If the netshot is tight and tumbling,
                    then the opponent's lift will not reach the back of the
                    court, which makes the subsequent smash much harder to
                    return. Deception is also important. Expert players prepare
                    for many different strokes look identical, and use slicing
                    to deceive their opponents about the speed or direction of
                    the stroke. If an opponent tries to anticipate the stroke,
                    he may move in the wrong direction and may be unable to
                    change his body momentum in time to reach the shuttlecock.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
        <BadmintonGallery/>
        {/* <!-- Team member --> */}
        <TeamMember 
          name="MR. MARCEL EGUABOR"
          title="LCC CHAIRMAN BADMINTON"
          img="./assets/images/members/CHAIRMAN_BADMINTON_SECTION.jpeg" />
        {/* <!-- Team member --> */}
      </div>
      <HeroFooter />
    </div>
  );
}
