import React from "react";
import {
  AboutTeam,
  JoinClub,
  PageHeader,
  SectionHeader,
  TestimonialSilder,
} from "../../components";

export default function AboutPage() {
  return (
    <div className="page-content">
      <PageHeader title="About Us" page="about" />
      <div className="content">
        {/* <!-- About Company --> */}
        {/* <!-- Our Awesome Services --> */}
        <div className="section-full bg-white content-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center section-head">
                <h3 className="h3"> WHO WE ARE</h3>
                <div className="dlab-separator bg-primary"></div>
                <div className="clear"></div>
                <p className="m-b0">
                  Founded on 3rd August 1949 and incorporated under the Land
                  Perpetual Succession Ordinance, CAP 107 on 30th October 1956.
                  The original site of the Club was the hanger of the Royal Air
                  Force by the West African Airways Corporation (WAAC) owned by
                  Anglophone West African Countries of Nigeria, Ghana, Sierra
                  Leone and the Gambia. Upon independence of Nigeria in October
                  1960 WAAC was changed to Nigeria Airways.
                </p>
              </div>
              <div className="clearfix">
                <div className="col-md-12 col-sm-12">
                  <div className="icon-bx-wraper bx-style-2 m-l40 m-b30 p-a30 left">
                    <div className="icon-bx-sm radius bg-primary">
                      <a href="/#" className="icon-cell">
                        <i className="fa fa-home"></i>
                      </a>
                    </div>
                    <div className="icon-content p-l40">
                      <h4 className="dlab-tilte">History</h4>
                      <p className="text-gray-dark">
                        The original site of the Club was the hanger of the
                        Royal Air Force by the West African Airways Corporation
                        (WAAC) owned by Anglophone West African Countries of
                        Nigeria, Ghana, Sierra Leone and the Gambia. Upon
                        independence of Nigeria in October 1960 WAAC was changed
                        to Nigeria Airways..
                        <br /> <br />
                        The Club moved from its original site to a second site
                        sharing its boundaries with the Grange School and John
                        Holt Supermarket at the Air Traffic Section Wing of the
                        Murtala Mohammed Airport. The Club however moved to its
                        present site covering over 5 acres on 18th May 1963 and
                        has since achieved modest developments in
                        infrastructure, personnel, members and status since
                        then. The first Chairman of the Club was Mr. R. Simpson
                        (a Briton) while the first Nigerian was a Medical Doctor
                        Dr. Williams.
                        <br /> <br />
                        As at 1951, the membership strength of the Club was a
                        modest range of between 75 and 100. This rose to about
                        500 in 1963. The Club has grown from the initial section
                        of Snooker/Billiards and a Main Bar + Darts to 10
                        different Sections. The Club is governed by “The
                        Constitution 2013”. The first Constitution was revised
                        in 1954. Subsequently Constitutions were also made in
                        1984 and further revised in 1997.
                        <br /> <br />
                        THE LEGAL OWNERS OF THE Club are formed into a Board of
                        Trustees comprising 5 distinguished gentlemen. Lagos
                        Country Club has over the years grown to be regarded as
                        one of the Top Social, Recreational and Sporting Clubs
                        in Nigeria with over 2000 active members, having
                        membership that cuts across nationalities, tribes and
                        multi-cultures. Lagos Country Club is the premier Family
                        Club in Nigeria.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- About Company END --> */}
        {/* <!--Join Club section --> */}
        <JoinClub
          title="CLUB ANTHEM"
          desc="Lagos Country Club founded 1949
          A Second Home for the family
          A model in Sports and Social harmony
          A haven for you and me.
          
          Together we shall make it greater (2ce)
          All hail Lagos Country Club
          Where good things happen."
        />
        {/* <!-- End of Join Club section --> */}
        <div class="section-full bg-white content-inner">
          <div class="container">
            <div class="row">
              {/* <!-- Section Header --> */}
              <SectionHeader title="MILESTONE" />
              {/* <!-- ENd Section Header --> */}
              <div class="clearfix">
                <div class="col-md-6 col-sm-6">
                  <div class="icon-bx-wraper bx-style-2 m-l40 m-b30 p-a30 left">
                    <div class="icon-bx-sm radius bg-primary">
                      <a href="#" class="icon-cell">
                        <i class="fa fa-check"></i>
                      </a>
                    </div>
                    <div class="icon-content p-l40">
                      <h4 class="dlab-tilte">
                        Founding of Badminton Section, Table Tennis
                      </h4>
                      <p>1955:</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="icon-bx-wraper bx-style-2 m-l40 m-b30 p-a30 left">
                    <div class="icon-bx-sm radius bg-primary">
                      <a href="#" class="icon-cell">
                        <i class="fa fa-check"></i>
                      </a>
                    </div>
                    <div class="icon-content p-l40">
                      <h4 class="dlab-tilte ">
                        Founding of the Main Bar & Snooker, Billiards Section,
                        Darts, Lawn Tennis
                      </h4>
                      <p>1955:</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="icon-bx-wraper bx-style-2 m-l40 m-b30 p-a30 left">
                    <div class="icon-bx-sm radius bg-primary">
                      <a href="#" class="icon-cell">
                        <i class="fa fa-check"></i>
                      </a>
                    </div>
                    <div class="icon-content p-l40">
                      <h4 class="dlab-tilte ">
                        Founding of Swimming Section in March
                      </h4>
                      <p>1987</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="icon-bx-wraper bx-style-2 m-l40 m-b30 p-a30 left">
                    <div class="icon-bx-sm radius bg-primary">
                      <a href="#" class="icon-cell">
                        <i class="fa fa-check"></i>
                      </a>
                    </div>
                    <div class="icon-content p-l40">
                      <h4 class="dlab-tilte ">
                        Introduction of Car Stickers to the Club
                      </h4>
                      <p>1981-82:</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="icon-bx-wraper bx-style-2 m-l40 m-b30 p-a30 left">
                    <div class="icon-bx-sm radius bg-primary">
                      <a href="#" class="icon-cell">
                        <i class="fa fa-check"></i>
                      </a>
                    </div>
                    <div class="icon-content p-l40">
                      <h4 class="dlab-tilte ">
                        Relocation of LCC to its present site
                      </h4>
                      <p>May 18th 1963:</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="icon-bx-wraper bx-style-2 m-l40 m-b10 p-a30 left">
                    <div class="icon-bx-sm radius bg-primary">
                      <a href="#" class="icon-cell">
                        <i class="fa fa-check"></i>
                      </a>
                    </div>
                    <div class="icon-content p-l40">
                      <h4 class="dlab-tilte">
                        The founding of Lagos Country Club, Ikeja
                      </h4>
                      <p>August 3rd 1949:</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Testimonials blog --> */}
        <div
          className="section-full overlay-black-middle bg-img-fix content-inner-1"
          style={{ backgroundImage: "url(./assets/images/background/bg2.jpg)" }}
        >
          <div className="container">
            {/* <!-- Section Header --> */}
            <div className="text-white">
              <SectionHeader titleSub="Members Testimonial" />
            </div>
            {/* <!-- ENd Section Header --> */}
            <div className="section-content">
              <div className="testimonial-two">
                <TestimonialSilder />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Testimonials blog END --> */}
      </div>
    </div>
  );
}
