const badmintonData = [
  {
    id: 1,
    alt: "gallery image",
    img: "/assets/images/section/Baldminton/b1.jpg",
  },
  {
    id: 2,
    alt: "gallery image",
    img: "/assets/images/section/Baldminton/b2.jpg",
  },
  {
    id: 3,
    alt: "gallery image",
    img: "/assets/images/section/Baldminton/b3.jpg",
  },
  {
    id: 4,
    alt: "gallery image",
    img: "/assets/images/section/Baldminton/b4.jpg",
  },
  ,
  {
    id: 5,
    alt: "gallery image",
    img: "/assets/images/section/Baldminton/b5.jpg",
  },
  {
    id: 6,
    alt: "gallery image",
    img: "/assets/images/section/Baldminton/b6.jpg",
  },
];
export default badmintonData;
