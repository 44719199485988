import React from "react";
import { PageHeader, TeamMember } from "../../components";
import TableTennisGallery from "./directory/tableTennis";

export default function TableTennis({ data }) {
  return (
    <div className="page-content">
      <PageHeader title="Table Tennis" page="table tennis" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact -> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div class="section-fullbg-gray content-inner bg-gray ">
            <div class="container">
              <div class="row">
                <div class="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="/assets/images/gallery/tt1.jpg"
                    alt=""
                    class="m-b30"
                  />
                </div>
                <div class="col-md-7 col-sm-12">
                  <h3 class="h2 text-uppercase">WELCOME TO Table Tennis</h3>
                  <div class="dlab-separator bg-primary"></div>
                  <h4 class="font-weight-3 00">
                    You are welcome to the Table table tennis Section of the
                    Lagos Country Club. The sports for the active mind and body,
                    loads of excitement awaits you with standard equipment in an
                    ambiance of total relaxation.
                  </h4>

                  <p>
                    The section is ever a beehive of activities as members and
                    visitors get engaged with several tournaments during the
                    course of the year. From annual title sponsored tournaments
                    to American tournament to impromptu and special occasions’
                    tournaments. The Tennis Section is busy all year round. No
                    wonder Tennis Section is the In fact it is the heartbeat of
                    the club.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Team member --> */}

          {/* <!-- Gallery section --> */}
          <TableTennisGallery />
          {/* <!--End Gallery section --> */}

          <TeamMember
            name="ALH. TUNJI LAWAL"
            title="Chairman Table Tennis Sections."
            img="./assets/images/members/LCC_CHAIRMAN_TABLE_TENNIS.jpeg"
          />
          {/* <!-- Team member --> */}
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
