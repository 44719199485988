import React, { useState } from "react";
import Menu from "./Menu";
import Categories from "./Categories";
import items from "./data";
import SectionHeader from "../sectionItem/SectionComponent";

const allCategories = [ ...new Set(items.map((item) => item.category))];

function GalleryApp() {
  const [menuItems, setMenuItems] = useState(items.slice(0, 6));
  const [categories, setCategories] = useState(allCategories);

  const filterItems = (category) => {
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };
  return (
    <>
      <div
        className="section-full text-center text-white bg-img-fix content-inner overlay-black-middle our-projects-galery"
        style={{ backgroundImage: "url(./assets/images/background/bg1.jpg)" }}
      >
        <div className="container">
          {/* <!-- Section Header --> */}
          <SectionHeader titleSub={`Our Gallery`} />
          {/* <!-- ENd Section Header --> */}

          <Categories categories={categories} filterItems={filterItems} />
          <div className="row">
            <ul className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery m-b0">
              <Menu items={menuItems} />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default GalleryApp;
