import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components'
import axios from 'axios'
import routes from '../../routes/routes'

const HallBooking = () => {
    const [showMessage, setShowMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showInput, setShowInput]= useState('none');
    const [errorResult, seterrorResult]= useState('');
    const [successResult, setsuccessResult]= useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [hallType, setHallType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [guestnum, setGuestnum] = useState('');
    const [status, setStatus] = useState('');
    const [purpose, setPurpose]=useState('');
    const [email, setEmail]=useState('');
    const [phone, setPhone] = useState('');
    const [memberId, setMemberId] =useState('');
    const booking = {
        name: name,
        phone:phone,
        address: address,
        hallType: hallType,
        event_start_date:startDate,
        guestnum:guestnum,
        memberId:memberId,
        status:status,
        purpose:purpose,
        email:email
    }
    const localhost = "https://test.lagoscountryclub.net/api/addBooking"
    const header = {
        'Accept':"application/json",
        'Content-Type': 'application/json',
    }
    
const hallBooking = () =>{
    console.log('boking details is '+booking)
    setLoading(true);
    setsuccessResult('');
    seterrorResult('');
    fetch("https://test.lagoscountryclub.net/api/addBooking",{ 
        method:"Post",
        body: JSON.stringify(booking),
        headers: header,
    }).then((response)=>{
        response.json().then((res)=>{
            // console.log(res.message);
            setLoading(false);
            setShowMessage(true);
            console.log(res)
            if(res.message==='success'){
                // responseMessage = "<div className='bg-success'>Your reservation has been made. Kindly proceed to payment to confirm your reservation in less than 6 hours </div>";
                document.getElementById("Bookform").reset();
                setsuccessResult("Your reservation has been made. Kindly proceed to payment to confirm your reservation in less than 6 hours ");
                setName('');
                setAddress('');
                setHallType('');
                setStartDate('');
                setGuestnum('');
                setStatus('');
                setPurpose('');
                setPhone('');
                setMemberId('');
                setEmail('');
            }else{
                seterrorResult(res.message);
                
            }
        })
    })
}
    return (
        <div className='page-content'>
            <PageHeader title="Hall Booking" page="hallbooking" />
            <div className='container'>
                <div className='row'>
                 
                <h2>Reserve Hall</h2>

                    <div className='col-md-8 p-a30 bg-gray clearfix m-b30 '>
                    {
                    showMessage ? 
                    <div className='message-div' style={{position:'sticky', margingTop:'3%'}}>  
                        {
                            successResult? <div className='success' style={{fontSize:'20px',color:'white', backgroundColor:'#18f0a87c',padding:'2%'}}>{successResult}
                            <span className="closebtn" onClick={()=>{setShowMessage(false)}}>&times;</span> 
                            </div> : null
                        }
                        { errorResult ?
                        <div className='warning' style={{fontSize:'18px',color:'white',backgroundColor:'red',padding:'2%'}}>
                        <span className="closebtn" onClick={()=>{setShowMessage(false)}}>&times;</span> 
                        {errorResult}</div> : null
                        }
                    </div> 
                    : null
                }
                        <div className=' p-5'>
                            
                    <form className='form card p-5 border' id='Bookform'>
                        <div className='row form-group'>
                            <div className='col-sm-6 '>
                            <label htmlFor="name">Full name</label>
                                <input required='required' value={name} onChange={e =>{setName(e.target.value)}} type='text' id='name' name='name' className='form-control' />
                            </div>
                            <div className='col-sm-6 '>
                            <label htmlFor="address">Address</label>
                                <input required='required' value={address} onChange={e =>{setAddress(e.target.value); 
                                    }} type='text' id='address' name='address' className='form-control'/>
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col-sm-4 '>
                                <label htmlFor="phone">Phone Number</label>
                                <input required='required' type='text' value={phone} id='phone' onChange={e=>{setPhone(e.target.value)}} name='phone' className='form-control'/>
                            </div>
                            <div className='col-sm-4 '>
                                <label htmlFor="email">Email Address</label>
                                <input required='required' type='text' value={email} onChange={e=>{setEmail(e.target.value)}} id='email' name='email' className='form-control'/>
                            </div>
                            <div className='col-sm-4 '>
                                <label htmlFor="purpose">Purpose of Use</label>
                                <input required='required' type='text' value={purpose} onChange={e=>{setPurpose(e.target.value)}} id='purpose' name='purpose' className='form-control'/>
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col-sm-6'>
                                <p>Your Status</p>
                                <div className='row form-group'>
                                    <div className='col-sm-6 '>
                                        <label>Member</label>
                                        <input value={status} type='radio' onClick={e=>{setStatus(e.target.value); setShowInput('block')}} id='status' name='status' className='form-control' value='member'/>
                                        <input value={memberId} className='form-control' type='text' style={{display:showInput}}  placeholder='Member ID' onChange={e=>{setMemberId(e.target.value)}}/>
                                    </div>
                                    <div className='col-sm-6 '>
                                        <label htmlFor="phone">Non-Member</label>
                                        <input value={status} type='radio' onClick={e=>{setStatus(e.target.value); setShowInput('none');setMemberId('NONE')}} id='status' name='status' className='form-control' value='non-member'/>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <p>Select Hall Type</p>
                                <div className='row form-group'>
                                    <div className='col-sm-6 '>
                                        <label>Diamond Hall</label>
                                        <input  type='radio' onClick={e=>{setHallType(e.target.value)}} id='halltype' name='halltype' className='form-control' value='diamond'/>
                                    </div>
                                    <div className='col-sm-6 '>
                                        <label htmlFor="phone">Little Theatre</label>
                                        <input type='radio' onClick={e=>{setHallType(e.target.value)}} id='halltype' name='halltype' className='form-control' value='little theatre'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='row'>
                            {/* <div className='col-sm-4 form-group'> */}
                                {/* <label htmlFor="hallType">HallType</label>
                                <select onChange={e =>{setHallType(e.target.value);}} id='hallType' name='hallType' placeholder='Select Hall' className='form-control' >
                                    <option value='small'>Small NGN 300,000</option>
                                    <option value='large'>Large NGN 700,000</option>
                                </select> */}
                                {/* <input onChange={e =>{setHallType(e.target.value); console.log(hallType);}} id='hallType' name='hallType' placeholder='Select Hall' className='form-control' /> */}
                            {/* </div> */}
                            <div className='col-sm-6 form-group'>
                            <label htmlFor="eventDate">Event Date</label>
                                <input required='required' value={startDate} onChange={e =>{setStartDate(e.target.value)}} type='date' id='eventDate' name='eventDate' className='form-control'/>
                            </div>
                            <div className='col-sm-6 form-group'>
                            <label htmlFor="guestnum">Number of Expected Guest</label>
                                <input required='required' value={guestnum} onChange={e =>{setGuestnum(e.target.value)}} type='number' id='guestnum' name='guestnum' className='form-control'/>
                            </div>
                        </div>
                        <div>
                            <button type='button' onClick={hallBooking} aria-pressed="true" className='site-button'>Reserve Hall Now</button>
                        </div>
                    </form>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <h2>Hall Reservation Rate</h2>
                        <div>
                        <table className="table table-striped">
  <thead>
    <tr>
      <th scope="col">Day</th>
      <th scope="col">Diamon Hall</th>
      <th scope="col">Little Theatre</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>MON to WED</td>
      <td>N756,125 (Members) <br/> N904,375(Non-Members) </td>
      <td>N362,500 (Members)<br/>N508,750 (Non-Members) </td>
    </tr>
    <tr>
      <td>THU to FRI</td>
      <td>N756,125 (Members) <br/> N904,375(Non-Members) </td>
      <td>N362,500 (Members)<br/>N508,750 (Non-Members) </td>
    </tr>
    <tr>
      <td>SAT</td>
      <td>N756,125 (Members) <br/> N904,375(Non-Members) </td>
      <td>N362,500 (Members)<br/>N508,750 (Non-Members)</td>			
    </tr>
    <tr>
      <td>SUN</td>
      <td>N756,125 (Members) <br/> N904,375(Non-Members) </td>
      <td>N362,500 (Members)<br/>N508,750 (Non-Members)</td>			
    </tr>
  </tbody>
</table>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? 
            <div className='loading-box'> 
                 <div className='loading-pic'> </div>
            </div> : null
            }
        </div>
    )
}

export default HallBooking
