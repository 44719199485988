const tennisData = [
    {
      id: 1,
      alt:'gallery image',
      img:'/assets/images/section/tennis/T1.jpg',
    },
    {
      id: 2,
      alt:'gallery image',
      img:'/assets/images/section/tennis/T2.jpg',
    },
    {
      id: 3,
      alt:'gallery image',
      img:'/assets/images/section/tennis/T3.jpg',
    },
    {
      id: 4,
      alt:'gallery image',
      img:'/assets/images/section/tennis/T4.jpg',
    },
    {
      id: 5,
      alt:'gallery image',
      img:'/assets/images/section/tennis/T5.jpg',
    },
    {
      id: 6,
      alt:'gallery image',
      img:'/assets/images/section/tennis/T6.jpg',
    },
    
  ];

  export default tennisData;