import React, { useEffect, useState } from 'react'
import axios from 'axios';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BlogSection } from '../../..';
import routes from '../../../../routes/routes';
import BlogCard from '../../blogCard';

SwiperCore.use([Navigation, Pagination, Autoplay])

const BlogDirectory = () => {
    const [events, setEvents] = useState([])

    console.log('loging here right now');
    useEffect(() => {
        axios.get(routes.api.getEvent)
            .then(res => {
                setEvents(res.data);
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])
    const params = {
        grabCursor: true,
        centeredSlides: false,
        slidesPerView: "1",
        autoplay: {
            delay: 4500,
            disableOnInteraction: false
        },
        // breakpoints: {
        //     1024: {
        //         slidesPerView: 2,
        //         spaceBetween: 40
        //     },
        //     600: {
        //         slidesPerView: 1,
        //         spaceBetween: 0
        //     },
        //     320: {
        //         slidesPerView: 1,
        //         spaceBetween: 0
        //     }
        // },


        


        
        






        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    }
    return (
        <>
            <Swiper 
                spaceBetween={50}
                slidesPerView={3}
                pagination={{
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                    dynamicBullets: true
                }}
                autoplay={{
                    delay: 5000
                }}
                navigation
            >
            {events && 
                events.map((item, index) => (
                    <SwiperSlide>
                        <BlogCard {...item} />

                    </SwiperSlide>
                ))
            }
        </Swiper>

        </>
    )
}


export default BlogDirectory;


