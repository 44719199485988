import React from "react";
import { PageHeader } from "../../components";

export default function EventsHanding() {
  return (
    <div className="page-content">
      <PageHeader
        title="Bolaji David’s Memorial Scrabble Classics."
        page="Event details"
      />
      {/* <!-- Content --> */}
      <div className="page-content">
        <div class="section-full bg-white content-inner">
          <div class="container woo-entry">
            <div class="row">
              <div class="col-md-4">
                <div class="clearfix">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d126848.81976376878!2d3.3372391044290053!3d6.518440971475161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d6.4493912!2d3.4498355999999997!4m5!1s0x103b92159b76a9ab%3A0xaf8f3720edbd42bf!2slagos%20country%20club!3m2!1d6.5876472999999995!2d3.3564287!5e0!3m2!1sen!2sng!4v1613998267098!5m2!1sen!2sng"
                    style={{ border: 0, width: "100%", height: 400 }}
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                  <div class="dlab-divider bg-gray-dark">
                    <i class="icon-dot c-square"></i>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="dlab-media m-b30">
                  <a href="#">
                    <img
                      src="./assets/images/blog/grid/pic1.jpeg"
                      alt=""
                      width="200"
                      height="160"
                    />
                  </a>
                </div>

                <div class="dlab-tabs border bg-tabs product-description">
                  <div class="tab-content">
                    <div class="tab-pane active">
                      <h3 class="m-t0">
                        The Scrabble section came alive on the 15th of May, 2021
                        as it played host to the Scrabble enthusiast all over
                        the country in what turned out to be a tourney that
                        produced a new winner. The tourney was put together to
                        celebrate the life of one of the elders in the Club that
                        passed onto greater glory late last year. Air. Comdr.
                        Bolaji David, a perfect gentleman of the Club, passed
                        away on October 1, 2020, on the day the Club was
                        electing new officers into the Management Council.
                      </h3>
                      <p class="m-b10">
                        Prior to his death, he was a veteran of the game who
                        commanded a lot of respect in and outside the Club. The
                        one day tourney had 20 participants drawn from all over
                        the country, it was an open tournament and as such, most
                        participants were not members of the Club, they were
                        veterans scrabble players who have made their marks in
                        the world of scrabble , most had represented their
                        states and the Country before in tournaments in and
                        outside rhe Country. Not to be left out and to prove the
                        caliber of players in the Scrabble Section of Lagos
                        Country Club, some members of the Club also took part in
                        the tourney.
                      </p>
                      <p class="m-b10">
                        Mr. Kenneth Ezaga, the perennial Champion of the
                        Scrabble section of the Club, proved to be too hot for
                        the veterans scrabble players as he emerged the overall
                        winner of the tournament scoring 8 wins with 941
                        cumulative points. He edged out his closest rival, Dr.
                        Kayode who had 8 wins and 574 cumulative points to win
                        the beautiful 250ltr Ignis Freezer. Other members of the
                        Club who participated also won some consolation prizes,
                        members of the Club came 6th, 7th and 8th after losing
                        the 2nd, 3rd, 4th and 5th positions to some of the
                        veterans. The memorial tournament will be an annual
                        event I the Club and it will become even bigger as the
                        years run by.
                      </p>
                      <p class="m-b10">
                        The President of the Club, Arc. Funmi Bamkole was in
                        attendance to present the prizes to the winners. Patrons
                        of the Section, Otunba Wale Dada Bello and Otunba Dele
                        Opanuga came to give their support to the players. The
                        Management Committee of the Section, ably led by the
                        Chairman, Asiwaju Adesina Oderinde put together a world
                        class tourney and ensured everybody present had a good
                        time. There was plenty to drink and dine on, while a
                        melodious band was on hand to ease the stress of the
                        brain in the evening. We pray for the continued repose
                        of the soul of Air. Comdr. Bolaji David. Amen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
