import React from "react";
import  HomeTeam from "../homeTeam";

const ClubTeam = ({ Items }) => (
  <>
    {Items
      .filter((item, idx) => idx < 3)
      .map(({ id, ...otherItemProps }) => (
        <HomeTeam key={id} {...otherItemProps} />
      ))}
  </>
);

export default ClubTeam;
