import React from 'react';
import { PageHeader } from "../../../components";

export default function OTHERGAME() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/Chairman_other_games.jpeg"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">PRINCESS TOLA ADELE OLADOYIN</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                  She is an active member of our prestigious Club, she was the Past Sport Secretary of the Club, a past Captain of Darts Section and past Vice Chairman of Other Games section. She was a formal National Table Tannis player and Formal National Female Dart champion. A former South West Zonal Coordinator of Dart Federation of Nigeria (DFN). 
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
