import React from "react";

const Trustees = () => (
  <>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box  team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/KOLAWOLE_OYEFESO.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
            AREE KOLAWOLE OYEFESO
          </h4>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/ADEKUNLEADESINA.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
            PRINCE (HON.) ADEKUNLE ADESINA
          </h4>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box m-b30 team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/CHIEF_ALLAN_WILLIAMS.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="">
          <h4 className="dlab-title text-uppercase">CHIEF ALLAN OLUMUYIWA WILLIAMS</h4>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box m-b30 team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/CHIEF_OLIVER_OGBONNA.jpg"
            alt="Buhari"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
             CHIEF OLIVER OGBONNA
          </h4>
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-6">
      <div className="dlab-box m-b30 team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/AKINDELE_AKINTOYE.jpg"
            alt="image"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">
            BARRISTER AKINDELE AKINTOYE
          </h4>
        </div>
      </div>
    </div>
   
  </>
);

export default Trustees;
