const TableTennisData = [
  {
    id: 1,
    alt: "gallery image",
    img: "/assets/images/section/tableTennis/tt1.jpg",
  },
  {
    id: 2,
    alt: "gallery image",
    img: "/assets/images/section/tableTennis/tt2.jpg",
  },
  {
    id: 3,
    alt: "gallery image",
    img: "/assets/images/section/tableTennis/tt3.jpg",
  },
  {
    id: 4,
    alt: "gallery image",
    img: "/assets/images/section/tableTennis/tt4.jpg",
  },
  {
    id: 5,
    alt: "gallery image",
    img: "/assets/images/section/tableTennis/tt5.jpg",
  },
  {
    id: 6,
    alt: "gallery image",
    img: "/assets/images/section/tableTennis/tt6.jpg",
  },
];

export default TableTennisData;
