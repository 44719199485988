import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { PageHeader } from "../../components";
import { useMember } from "../../context/memberContext";
import secureLS from "../../encryption/secureLs";
import routes from "../../routes/routes";
import {useRef} from "react";
export default function ResetPassword() {
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm();
    // const mutation = useMutation(Data => axios.post(routes.api.resetPassword, Data))
    const mutation = useMutation(Data => axios.post(routes.api.resetPassword, Data))
    const [message, setMessage] = useState("")
    // const {state,dispatch} = useMember()
    console.log(secureLS.get("member"));
    const  email= useRef();

    const tokenUrl = 'https:/message.lagoscountryclub.net/get_token.php';
    const   resetPassword=(e)=>{
        e.preventDefault();
        const resetData = {
            userEmail : email.current.value,
        }
        axios.post(tokenUrl,resetData)
        .then((response)=>{
            console.log(response);
            setMessage(["Successful. An OTP as been sent to your email address. Kindly follow the instruction in the mail to reset your password"])           
        })
    }
    
    // const onSubmit = async (inputData) => {
    //     await mutation.mutate(inputData, {
    //         onSuccess: async (data) => {
    //             await setMessage(["Successful. An OTP as been sent to your email address. Kindly follow the instruction in the mail to reset your password"])
    //             console.log(data.data.member); 
    //           },
    //           onError: async (error) => {
    //               setMessage(["Member Id does not exist"])
    //           },
    //     })
        
    // };
    return (
        <>
          <PageHeader title="Reset Password" page="Reset Password" />
            <div className="container" style={{ margin: "200px", marginTop: 20 }}>
                <div className="row">
                    <div className="col-md-7 col-md-offset-1 p-lr40 p-t30 p-b20 clearfix mack-an-appointment black border-1">
                        <div className="card card-body">
                            <h1 className="text-center mb-3">
                                <i className="fas fa-sign-in-alt"></i>
                                Reset password
                            </h1>
                            <p className="text-success">{message[0]}</p>
                            <form className="resetPassForm" onSubmit={resetPassword}>
                                <div className="form-group">
                                    <label htmlFor="email">Registered Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Enter email"
                                        ref={email}
                                        required
                                    />
                                    
                                    <ErrorMessage 
                                        errors={errors}
                                        name="email"
                                        render={(messages) => {
                                            console.log("messages", messages);
                                            return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                    <p className="text-danger" key={type}>{message}</p>
                                                ))
                                                : null;
                                        }}
                                    />
                                </div>
                               
                                <div className="col-md-12 text-center">
                                    <button
                                        name="submit"
                                        type="submit"
                                        className="site-button"
                                        
                                        style={{ fontSize: 20, fontWeight: 500 }}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
