import GrandPatron from "./GRANDPATRON";
import Patron from "./PATRON";
import VicePatron from "./Vice/index";
import Trustees from "./Trustees/index";
import LCCPatron from "./LCC/index";


export const  GrandPatrons = GrandPatron
export const  Patrons = Patron
export const VicePatrons = VicePatron
export const Trusteess = Trustees
export const LCCPatrons = LCCPatron