import React from "react";
import {  PageHeader, TeamMember } from "../../components";
import style from "./style.module.css";
export default function OtherGames() {
  return (
    <div className="page-content">
      <PageHeader title="Other games" page="other-games" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- contact -> */}
        <div className="content">
          {/* <!-- About Company --> */}
          {/* <!-- Our Awesome Services --> */}
          <div className="section-full bg-gray content-inner ">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center section-head mamber-dec">
                  <h3 className="h2">WELCOME TO OTHER GAMES</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <div className="clear"></div>
                  <h5 class="font-weight-3 m-b30">..........</h5>
                  {/* <a href="/#" className="site-button">Learn More</a> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- About Company END --> */}
          {/* <!-- Gallery section --> */}

          {/* <!-- Gallery section END --> */}

          {/* <!-- Team member --> */}
          {/* <TeamMember
            name="PSTR.  OYEYEMI TAIWO"
            title="Chairman For Other Games"
            img="./assets/images/members/Chairman_other_games.jpeg"
          /> */}
          <TeamMember
            name="PRINCESS. TOLA ADELE OLADOYIN"
            title="Chairman For Other Games"
            img="./assets/images/members/Chairman_other_games.jpeg"
          />
          {/* <!-- Team member --> */}
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
