import React from "react";
import { PageHeader } from "../../../components";

export default function SECRETARYGENERAL() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_SECRETARY_GENERAL.JPEG"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">DR. TUNDE BAJELA</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h5 className="font-weight-3 00">
                  Dr Tunde Bajela is the Managing Consultant/Chief Executive of Superbrains Consulting Limited, a financial advisory company and the Managing Director/Chief Executive of Hygges Signature Limited, a real estate company. He is also the managing partner of the firm of Chartered Accountants, Tunde Bajela & Co. His professional certifications include the fellowships of both the Chartered Institute of Bankers of Nigeria (FCIB) and the Institute of Chartered Accountants of Nigeria (FCA). He is also a professional member of the Institute of Marketing of Nigeria (NIMN) as well as a Chartered Arbitrator. With over 3 decades of professional experience in banking, finance, and accounting, he earned a Bachelor of Laws (LLB) degree from the university of London, a Masters degree in Applied Business Research and doctorate degree in business administration, both from the Swiss Business School, Zurich Switzerland.
                  <br></br>Armed with a Masters of Business Administration (MBA) degree acquired after a Bachelor of Science degree from the University of Ife (Now Obafemi Awolowo University) in 1987, Tunde had a fulfilling and highly distinguished career spanning over 21 years in the banking industry, where he held top management positions with leadership responsibilities in the areas of Retail, Commercial and Corporate Banking industry in January 2012, he established Superbrains Consulting Limited a financial advisory firm that is involved in delivering high quality consultancy services to private and public sector, organizations across the Country in Credit Risk Management, Accounting, Taxation, Corporate Finance and Strategic Planning and Arbitration. In 20018, he founded the professional service firm, Tunde Bajela and Co. (Chartered Accountants) to focus specifically on accounting, taxation and auditing. Later in 2020, he partnered with other investors to set up Hygges Signature Limited, a real estate company.
                  <br></br>Tunde has participated in more than 200 professional seminars, conferences and training programmes at home and abroad and he is an alumnus of 3 prestigious business school, the Lagos Business School (Senior Management Programme) Havard Business School (programme for leadership Development) INSEAD Fontainebleau, France (Risk Management in Banking). His involvement with the public sector in the area of policy articulation and development in his career includes membership of the FGN/NNPC delegation on a tour of China in 2007, to inspect industrial parks, petrochemical plants and refineries with a view to providing guidance for the development of an industrial policy for Nigeria. Tunde is happily married and blessed with children and his hobbies includes, music, sports, travelling and current affairs. He is a member of Lagos Country Club, the IBB Golf Club, and the Ikeja Golf Club.

                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
