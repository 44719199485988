import React, { useEffect, useState } from 'react'
import axios from 'axios';
import routes from '../../routes/routes';

import BlogData from "./blogdata"
import BlogList from './bloglist';

const BlogEvent = () => {
    const [events, setEvents] = useState([])

    useEffect(() => {
        axios.get("https://test.lagoscountryclub.net/api/events")
            .then(res => {
                setEvents(res.data)
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <>
        { events && events.map((item, index) => (
                        <BlogList {...item} />
                ))}
        </>
      )
}


export default BlogEvent;


