import React from "react";
import { PageHeader, TeamMember } from "../../components";
import DartGallery from "./directory/dart";

export default function Dart() {
  return (
    <div className="page-content">
      <PageHeader title="Darts"  page="darts" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          {/* <!-- Our Awesome Services --> */}
          <div className="section-full bg-gray content-inner ">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center section-head mamber-dec">
                  <h3 className="h2">WELCOME TO DARTS</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <div className="clear"></div>
                  <h5 class="font-weight-3 m-b30">
                    Easily the most vibrant Section in the Club. The Dart
                    Section has the most modern facilities for the game in the
                    country. It has four modern boards for players and two for
                    training.
                  </h5>
                  {/* <a href="/#" className="site-button">Learn More</a> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- About Company END --> */}

          <DartGallery />
          {/* <!-- Team member --> */}
          <TeamMember 
          name="PRINCE OWOLABI MUMUNI"
          title="LCC CHAIRMAN DARTS"
          img="./assets/images/members/LCC_CHAIREMAN_DARTS.JPG" />
          {/* <!-- Team member --> */}
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
