import React from "react";
import { PageHeader } from "../../../components";

export default function CHAIRMANSQUASH() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/chairman_squash.jpeg"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">SIR 'SEMOORE BADEJO, FIIM</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                  Semoore Badejo holds a first degree in Mass  Communication, a Masters Degree in Communication Arts and a Post Doctoral Research fellow in Psychology with special emphasis on Social Psychology and specifics on Mass Mobilisation.  He is armed with a career in Communication endeavours, that spanned over 35 years in print and  electronic media and, at various times worked in reputable Advertising and Public Relations Agencies. He is the CEO of Concrete Communications Ltd, A  Public Relations Consultancy firm with strong bias in IT and Mass Mobilization.
                  </h4>
                  <h4>
                  An avid reader of books on Spiritual upliftment, Software Engineering, psychology, human behaviour, consumer behaviour and literary expositions, he has attended many workshops, courses and seminars on Communications, sales, marketing, public relations and experiential marketing both within and outside the country. He has also been a facilitator in so many fora on communications related activities.
                  </h4>
                  <h4>
                  Semoore is a registered member of  The Advertising Practitioners Council of Nigeria (APCON), Nigerian Institute of Public Relations,(NIPR) Nigerian Union of Journalists (NUJ) and a Fellow, Institute of Information Management, Africa.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
